
import NotifySound from 'assets/sounds/notify.mp3'
import notificationTypes from '@/utils/notification-types'

export default {
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      subscription: null,
      notifications: [],
    }
  },
  computed: {
    latestNotifications() {
      return this.notifications.slice(0, 6)
    },
    unread() {
      return this.notifications.filter((item) => !item.read_at)
    },
  },
  mounted() {
    this.subscription = this.$cable.subscriptions.create(
      { channel: 'Noticed::NotificationChannel' },
      {
        received: async (data) => {
          try {
            const sound = new Audio(NotifySound)

            await sound.play()
          } catch (e) {
            this.$notification.info(
              this.$t('message.notification_need_user_iteration')
            )
          }

          this.notifications.unshift(data)
        },
      }
    )

    this.fetchNotifications()
  },
  methods: {
    async fetchNotifications() {
      if (this.$auth.loggedIn) {
        this.isLoading = true
        try {
          const { notifications } = await this.$axios.$get(
            '/api/v2/notifications',
            { params: { per_page: 6 } }
          )
          this.notifications = notifications
        } catch (e) {
          if (e.response.status) {
            this.$notification.error(this.$t('message.notification_fetch_failed'))
          }
        } finally {
          this.isLoading = false
        }
      }

    },
    handleAction({ id, type, params }) {
      this.subscription.perform('mark_as_read', { ids: [id] })

      this.notifications.find(
        (notification) => notification.id === id
      ).read_at = new Date()

      this.$router.push(this.localeRoute(notificationTypes[type].route(params)))
    },
  },
}
