
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    menuItems: {
      type: Array, // Suponiendo que los elementos del menú son un array de objetos
      default: () => [],
    },
  },
  methods: {
    handleVisible(value) {
      this.$emit('update:visible', value);
    },
    handleMenuItemClick(goto) {
      if (goto && goto.startsWith('#')) {
        this.$vuetify.goTo(goto)
      }
    }
  },
}
