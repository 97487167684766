
import { debounce } from 'lodash'

export default {
  data() {
    return {
      isLoading: false,
      searchTerm: this.$route.query.term,
      results: [],
    }
  },
  watch: {
    searchTerm: debounce(async function (value) {
      this.isLoading = true

      try {
        const { products } = await this.$axios.$get('/api/v1/public/products', {
          params: { term: value },
        })

        this.results = products
      } catch (e) {
      } finally {
        this.isLoading = false
      }
    }, 500),
  },
  methods: {
    handleChangeTerm() {
      this.$router.push(
        this.localeRoute({
          name: 'marketplace-products',
          query: { term: this.searchTerm },
        })
      )
    },
    handleChangeOption(value) {
      this.$router.push(
        this.localeRoute({
          name: 'marketplace-products',
          query: { term: value },
        })
      )
    },
  },
}
