
export default {
  props: {
    showSection: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      guideItems: [
        {
          text: this.$t('menu.how_to_activate'),
          href: '#',
        },
        {
          text: this.$t('menu.how_to_sell'),
          href: '#',
        },
        {
          text: this.$t('menu.how_to_withdraw'),
          href: '#',
        },
      ],
      contactChannelItems: [
        {
          text: this.$t('menu.email'),
          href: 'mailto:andres@alseco.cl',
        },
        {
          text: this.$t('menu.whatsapp'),
          href: 'https://wa.me/56951040888',
        },
        {
          text: this.$t('menu.youtube'),
          href: 'https://www.youtube.com/channel/UCdbRab2EZwTU1T2pREISOyw',
        },
      ],
      socialNetworksItems: [
        {
          text: this.$t('menu.facebook'),
          icon: 'mdi-facebook',
          href: 'https://www.facebook.com/alseco',
        },
        {
          text: this.$t('menu.linkedin'),
          icon: 'mdi-linkedin',
          href: 'https://www.linkedin.com/company/al-seco',
        },
        {
          text: this.$t('menu.instagram'),
          icon: 'mdi-instagram',
          href: 'https://www.instagram.com/alsecocl',
        },
      ],
    }
  },
}
