export const state = () => ({
  branches: [],
  pagination: {
    current_page: 1,
    per_page: 12,
    last_page: 0,
    total: 0,
  },
})

export const getters = {
  branches: (state) => state.branches,
  branchById:
    (state) =>
    ({ branchId }) =>
      state.branches.find((branch) => branch.id === branchId),
  pagination: (state) => state.pagination,
}

export const mutations = {
  SET_BRANCHES: (state, { branches }) => (state.branches = branches),
  ADD_BRANCH: (state, { branch }) => state.branches.unshift(branch),
  UPDATE_BRANCH: (state, { branch }) => {
    const branchFound = state.branches.findIndex(({ id }) => id === branch.id)

    if (branchFound === -1) return

    state.branches.splice(branchFound, 1, branch)
  },
  REMOVE_BRANCH: (state, { branchId }) => {
    const branchFound = state.branches.findIndex(({ id }) => id === branchId)

    if (branchFound === -1) return

    state.branches.splice(branchFound, 1)
  },
  SET_PAGINATION: (state, { meta }) => (state.pagination = meta),
}

export const actions = {
  async fetchBranches({ commit }, { commerceId, params = {} }) {
    return await this.$axios
      .$get(`/api/v1/private/commerces/${commerceId}/branches`, { params })
      .then(({ branches, meta }) => {
        commit('SET_BRANCHES', { branches })
        commit('SET_PAGINATION', { meta })

        return { branches, meta }
      })
  },
  async fetchBranch({ commit, getters }, { branchId }) {
    return await this.$axios
      .$get(`/api/v1/private/branches/${branchId}`)
      .then(({ branch }) => {
        getters.branchById({ branchId })
          ? commit('UPDATE_BRANCH', { branch })
          : commit('ADD_BRANCH', { branch })

        return { branch }
      })
  },
  async createBranch({ commit, getters }, { commerceId, data }) {
    return await this.$axios
      .$post(`/api/v1/private/commerces/${commerceId}/branches`, data)
      .then(({ branch }) => {
        commit('ADD_BRANCH', { branch })

        return { branch }
      })
  },
  async updateBranch({ commit, getters }, { branchId, data }) {
    return await this.$axios
      .$put(`/api/v1/private/branches/${branchId}`, data)
      .then(({ branch }) => {
        commit('UPDATE_BRANCH', { branch })

        return { branch }
      })
  },
  async deleteBranch({ commit, getters }, { branchId }) {
    return await this.$axios
      .$delete(`/api/v1/private/branches/${branchId}`)
      .then(() => {
        commit('REMOVE_BRANCH', { branchId })
      })
  },
}
