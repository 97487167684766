export default {
  NewOrderNotification: {
    route: ({ order = {} }) => {
      return {
        name: 'dashboard-orders-id',
        params: { id: order?.id },
      }
    },
  },
  NewInvitationNotification: {
    route: ({ collaborator = {} }) => {
      return {
        name: 'invitations-id',
        params: { id: collaborator?.id },
      }
    },
  },
}
