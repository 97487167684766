export default async ({ store, $auth }) => {
  try {
    if ($auth.loggedIn) {
      await Promise.all([
        store.dispatch('commerce/fetchCommerces', { params: { per_page: 100 } }),
        store.dispatch('commerce/fetchCollaborations', { params: { per_page: 100 } }),
      ]);

      const commerceIdStorage = $auth.$storage.getUniversal('commerce_id');

      if (!commerceIdStorage) {
        const firstCommerceWithCollaborations = store.getters['commerce/commerceWithCollaborations'][0];
        const firstCommerceId = firstCommerceWithCollaborations?.id;
        $auth.$storage.setUniversal('commerce_id', firstCommerceId);
      }

      const selectedCommerceId = $auth.$storage.getUniversal('commerce_id');
      store.dispatch('changeCommerce', { commerceId: selectedCommerceId });
    }
  } catch (error) {
    console.error(error);
  }
};
