
import { mapGetters } from 'vuex'

export default {
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      cartItemCount: 'cart/itemCount',
    }),
  },
}
