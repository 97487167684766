
export default {
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
    columnCount() {
      return Math.ceil(this.items.length / 6)
    },
  },
}
