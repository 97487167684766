export default ({ store }, inject) => {
  inject('can', (permissions) => {
    const collection =
      permissions instanceof Array ? permissions : [permissions]

    return store.getters['permission/permissions'].some((permission) =>
      collection.includes(permission)
    )
  })
}
