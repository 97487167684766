
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'MarketplacePublicLayout',
  middleware: [
    'commerceLoader',
    'branchLoader',
    'branchRequired',
    'isCommerceTypeWhosealer',
  ],
  data() {
    return {
      isMainDrawerVisible: false,
      isCategoryDrawerVisible: false,
      isVisibleBranchSelector: false,
    }
  },
  computed: {
    ...mapGetters({
      currentBranch: 'currentBranch',
      productCategories: 'productCategory/productCategories',
    }),
    },

  async mounted() {
    if (this.$auth.loggedIn) {
      this.updateCart();
    }

    try {
      await this.fetchProductCategories({
        params: {
          commerce_type: 'wholesaler',
          with_product: true,
          with_operative_branch: true,
          per_page: 9999,
        },
      });
    } catch (error) {
      console.error('Error al obtener las categorías de productos:', error);
    }
    if (!this.$auth.$storage.getUniversal('location')) {
      this.isVisibleBranchSelector = true;
    }
  },
  methods: {
    ...mapActions({
      updateCart: 'cart/updateCart',
      fetchProductCategories: 'productCategory/fetchProductCategories',
    }),
  },
}

