import Vue from 'vue'
import GmapVue from '~/node_modules/gmap-vue'

export default ({ $config }) => {
  Vue.use(GmapVue, {
    load: {
      key: $config.googleMapApiKey,
      libraries: $config.googleMapApiLibraries,
    },
    autobindAllEvents: true,
    installComponents: true,
  })
}
