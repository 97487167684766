export const state = () => ({
  branchCategories: [],
  pagination: {
    current_page: 1,
    per_page: 12,
    last_page: 0,
    total: 0,
  },
})

export const getters = {
  branchCategories: (state) => state.branchCategories,
  branchCategoryById:
    (state) =>
    ({ categoryId }) =>
      state.branchCategories.find((category) => category.id === categoryId),
  pagination: (state) => state.pagination,
}

export const mutations = {
  SET_BRANCH_CATEGORIES: (state, { branchCategories }) =>
    (state.branchCategories = branchCategories),
  ADD_BRANCH_CATEGORY: (state, { branchCategory }) =>
    state.branchCategories.unshift(branchCategory),
  UPDATE_BRANCH_CATEGORY: (state, { branchCategory }) => {
    const categoryFound = state.branchCategories.findIndex(
      ({ id }) => id === branchCategory.id
    )

    if (categoryFound === -1) return

    state.branchCategories.splice(categoryFound, 1, branchCategory)
  },
  REMOVE_BRANCH_CATEGORY: (state, { branchCategoryId }) => {
    const categoryFound = state.branchCategories.findIndex(
      ({ id }) => id === branchCategoryId
    )

    if (categoryFound === -1) return

    state.branchCategories.splice(categoryFound, 1)
  },
  SET_PAGINATION: (state, { meta }) => (state.pagination = meta),
}

export const actions = {
  async fetchBranchCategories({ commit }, { params = {} } = {}) {
    return await this.$axios
      .$get('/api/v1/public/branch-categories', { params })
      .then(({ branch_categories: branchCategories, meta }) => {
        commit('SET_BRANCH_CATEGORIES', { branchCategories })
        commit('SET_PAGINATION', { meta })

        return { branchCategories, meta }
      })
  },
  async fetchBranchCategory({ commit, getters }, { branchCategoryId }) {
    return await this.$axios
      .$get(`/api/v1/public/branch-categories/${branchCategoryId}`)
      .then(({ branch_category: branchCategory }) => {
        getters.branchCategoryById({ branchCategoryId })
          ? commit('UPDATE_BRANCH_CATEGORY', { branchCategory })
          : commit('ADD_BRANCH_CATEGORY', { branchCategory })

        return { branchCategory }
      })
  },
}
