export const state = () => ({
  products: [],
  pagination: {
    current_page: 1,
    per_page: 12,
    last_page: 0,
    total: 0,
  },
})

export const getters = {
  products: (state) => state.products,
  productById:
    (state) =>
      ({ productId }) =>
        state.products.find((product) => product.id === productId),
  pagination: (state) => state.pagination,
}

export const mutations = {
  SET_PRODUCTS: (state, { products }) => (state.products = products),
  ADD_PRODUCT: (state, { product }) => state.products.unshift(product),
  UPDATE_PRODUCT: (state, { product }) => {
    const productFound = state.products.findIndex(({ id }) => id === product.id)

    if (productFound === -1) return

    state.products.splice(productFound, 1, product)
  },
  REMOVE_PRODUCT: (state, { productId }) => {
    const productFound = state.products.findIndex(({ id }) => id === productId)

    if (productFound === -1) return

    state.products.splice(productFound, 1)
  },
  SET_PAGINATION: (state, { meta }) => (state.pagination = meta),
}

export const actions = {
  async fetchProducts({ commit }, { branchId, params = {} }) {
    return await this.$axios
      .$get(`/api/v1/private/branches/${branchId}/products`, { params })
      .then(({ products, meta }) => {
        commit('SET_PRODUCTS', { products })
        commit('SET_PAGINATION', { meta })

        return { products, meta }
      })
  },
  async fetchProduct({ commit, getters }, { productId }) {
    return await this.$axios
      .$get(`/api/v1/private/products/${productId}`)
      .then(({ product }) => {
        getters.productById({ productId })
          ? commit('UPDATE_PRODUCT', { product })
          : commit('ADD_PRODUCT', { product })

        return { product }
      })
  },
  async createProduct({ commit, getters }, { branchId, data }) {
    return await this.$axios
      .$post(`/api/v1/private/branches/${branchId}/products`, data)
      .then(({ product }) => {
        commit('ADD_PRODUCT', { product })

        return { product }
      })
  },
  async updateProduct({ commit, getters }, { productId, data }) {
    return await this.$axios
      .$put(`/api/v1/private/products/${productId}`, data)
      .then(({ product }) => {
        commit('UPDATE_PRODUCT', { product })

        return { product }
      })
  },
  async deleteProduct({ commit, getters }, { productId }) {
    return await this.$axios
      .$delete(`/api/v1/private/products/${productId}`)
      .then(() => {
        commit('REMOVE_PRODUCT', { productId })
      })
  },
  async createProductBatch({ commit, getters }, { branchId, products }) {
    return await this.$axios
      .$post(`/api/v1/private/branches/${branchId}/products/batch`, products)
      .then(({ products }) => {
        products.forEach((product) => {
          getters.productById({ productId: product.id })
            ? commit('UPDATE_PRODUCT', { product })
            : commit('ADD_PRODUCT', { product })
        })

        return { products }
      })
  }
}
