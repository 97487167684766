export const state = () => ({
  permissions: [],
})

export const getters = {
  permissions: (state) => state.permissions,
}

export const mutations = {
  SET_PERMISSIONS: (state, { permissions }) =>
    (state.permissions = permissions),
}

export const actions = {
  async fetchPermissions({ commit }, { branchId }) {
    return await this.$axios
      .$get(`/api/auth/user/permissions/${branchId}`)
      .then(({ permissions }) => {
        commit('SET_PERMISSIONS', { permissions })
      })
  },
}
