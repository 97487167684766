import { render, staticRenderFns } from "./Appbar.vue?vue&type=template&id=834e580a&"
import script from "./Appbar.vue?vue&type=script&lang=js&"
export * from "./Appbar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MarketplaceProductCategoryDropdown: require('/workspace/components/marketplace/product-category/Dropdown.vue').default,AccountDropdownMenu: require('/workspace/components/account/DropdownMenu.vue').default,AccountNotificationDropdown: require('/workspace/components/account/NotificationDropdown.vue').default,AccountCartDropdown: require('/workspace/components/account/CartDropdown.vue').default,MarketplaceFiltersSeeker: require('/workspace/components/marketplace/filters/Seeker.vue').default})
