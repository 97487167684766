export const AlertDialog = () => import('../../components/AlertDialog.vue' /* webpackChunkName: "components/alert-dialog" */).then(c => wrapFunctional(c.default || c))
export const DeleteDialog = () => import('../../components/DeleteDialog.vue' /* webpackChunkName: "components/delete-dialog" */).then(c => wrapFunctional(c.default || c))
export const GoogleMaps = () => import('../../components/GoogleMaps.vue' /* webpackChunkName: "components/google-maps" */).then(c => wrapFunctional(c.default || c))
export const MainNavigationTabs = () => import('../../components/MainNavigationTabs.vue' /* webpackChunkName: "components/main-navigation-tabs" */).then(c => wrapFunctional(c.default || c))
export const ShopNavigation = () => import('../../components/ShopNavigation.vue' /* webpackChunkName: "components/shop-navigation" */).then(c => wrapFunctional(c.default || c))
export const ShopPageHeader = () => import('../../components/ShopPageHeader.vue' /* webpackChunkName: "components/shop-page-header" */).then(c => wrapFunctional(c.default || c))
export const AccountAppbar = () => import('../../components/account/Appbar.vue' /* webpackChunkName: "components/account-appbar" */).then(c => wrapFunctional(c.default || c))
export const AccountCartDropdown = () => import('../../components/account/CartDropdown.vue' /* webpackChunkName: "components/account-cart-dropdown" */).then(c => wrapFunctional(c.default || c))
export const AccountCommerceList = () => import('../../components/account/CommerceList.vue' /* webpackChunkName: "components/account-commerce-list" */).then(c => wrapFunctional(c.default || c))
export const AccountDrawer = () => import('../../components/account/Drawer.vue' /* webpackChunkName: "components/account-drawer" */).then(c => wrapFunctional(c.default || c))
export const AccountDropdownMenu = () => import('../../components/account/DropdownMenu.vue' /* webpackChunkName: "components/account-dropdown-menu" */).then(c => wrapFunctional(c.default || c))
export const AccountNotificationDropdown = () => import('../../components/account/NotificationDropdown.vue' /* webpackChunkName: "components/account-notification-dropdown" */).then(c => wrapFunctional(c.default || c))
export const BranchBannersForm = () => import('../../components/branch/BannersForm.vue' /* webpackChunkName: "components/branch-banners-form" */).then(c => wrapFunctional(c.default || c))
export const BranchCard = () => import('../../components/branch/Card.vue' /* webpackChunkName: "components/branch-card" */).then(c => wrapFunctional(c.default || c))
export const BranchDeliveryMethodsForm = () => import('../../components/branch/DeliveryMethodsForm.vue' /* webpackChunkName: "components/branch-delivery-methods-form" */).then(c => wrapFunctional(c.default || c))
export const BranchFilters = () => import('../../components/branch/Filters.vue' /* webpackChunkName: "components/branch-filters" */).then(c => wrapFunctional(c.default || c))
export const BranchInformationForm = () => import('../../components/branch/InformationForm.vue' /* webpackChunkName: "components/branch-information-form" */).then(c => wrapFunctional(c.default || c))
export const BranchNavigationTabs = () => import('../../components/branch/NavigationTabs.vue' /* webpackChunkName: "components/branch-navigation-tabs" */).then(c => wrapFunctional(c.default || c))
export const BranchSaleRulesForm = () => import('../../components/branch/SaleRulesForm.vue' /* webpackChunkName: "components/branch-sale-rules-form" */).then(c => wrapFunctional(c.default || c))
export const BranchScheduleForm = () => import('../../components/branch/ScheduleForm.vue' /* webpackChunkName: "components/branch-schedule-form" */).then(c => wrapFunctional(c.default || c))
export const BranchSocialMediaForm = () => import('../../components/branch/SocialMediaForm.vue' /* webpackChunkName: "components/branch-social-media-form" */).then(c => wrapFunctional(c.default || c))
export const BankAccountCard = () => import('../../components/bank-account/Card.vue' /* webpackChunkName: "components/bank-account-card" */).then(c => wrapFunctional(c.default || c))
export const BankAccountDataForm = () => import('../../components/bank-account/DataForm.vue' /* webpackChunkName: "components/bank-account-data-form" */).then(c => wrapFunctional(c.default || c))
export const BankAccountSelector = () => import('../../components/bank-account/Selector.vue' /* webpackChunkName: "components/bank-account-selector" */).then(c => wrapFunctional(c.default || c))
export const BrandsFilterByName = () => import('../../components/brands/FilterByName.vue' /* webpackChunkName: "components/brands-filter-by-name" */).then(c => wrapFunctional(c.default || c))
export const CartItem = () => import('../../components/cart/Item.vue' /* webpackChunkName: "components/cart-item" */).then(c => wrapFunctional(c.default || c))
export const CartOrder = () => import('../../components/cart/Order.vue' /* webpackChunkName: "components/cart-order" */).then(c => wrapFunctional(c.default || c))
export const CartSummary = () => import('../../components/cart/Summary.vue' /* webpackChunkName: "components/cart-summary" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPaymentForm = () => import('../../components/checkout/PaymentForm.vue' /* webpackChunkName: "components/checkout-payment-form" */).then(c => wrapFunctional(c.default || c))
export const CollaboratorDeleteDialog = () => import('../../components/collaborator/DeleteDialog.vue' /* webpackChunkName: "components/collaborator-delete-dialog" */).then(c => wrapFunctional(c.default || c))
export const CollaboratorEditDialog = () => import('../../components/collaborator/EditDialog.vue' /* webpackChunkName: "components/collaborator-edit-dialog" */).then(c => wrapFunctional(c.default || c))
export const CollaboratorNewDialog = () => import('../../components/collaborator/NewDialog.vue' /* webpackChunkName: "components/collaborator-new-dialog" */).then(c => wrapFunctional(c.default || c))
export const CollaboratorTable = () => import('../../components/collaborator/Table.vue' /* webpackChunkName: "components/collaborator-table" */).then(c => wrapFunctional(c.default || c))
export const CommerceBranchList = () => import('../../components/commerce/BranchList.vue' /* webpackChunkName: "components/commerce-branch-list" */).then(c => wrapFunctional(c.default || c))
export const CommerceCard = () => import('../../components/commerce/Card.vue' /* webpackChunkName: "components/commerce-card" */).then(c => wrapFunctional(c.default || c))
export const CommerceFilters = () => import('../../components/commerce/Filters.vue' /* webpackChunkName: "components/commerce-filters" */).then(c => wrapFunctional(c.default || c))
export const CommerceInformationForm = () => import('../../components/commerce/InformationForm.vue' /* webpackChunkName: "components/commerce-information-form" */).then(c => wrapFunctional(c.default || c))
export const CommerceLegalRepresentativeForm = () => import('../../components/commerce/LegalRepresentativeForm.vue' /* webpackChunkName: "components/commerce-legal-representative-form" */).then(c => wrapFunctional(c.default || c))
export const CommerceLogoForm = () => import('../../components/commerce/LogoForm.vue' /* webpackChunkName: "components/commerce-logo-form" */).then(c => wrapFunctional(c.default || c))
export const CommerceNavigationMenu = () => import('../../components/commerce/NavigationMenu.vue' /* webpackChunkName: "components/commerce-navigation-menu" */).then(c => wrapFunctional(c.default || c))
export const CommerceNavigationTabs = () => import('../../components/commerce/NavigationTabs.vue' /* webpackChunkName: "components/commerce-navigation-tabs" */).then(c => wrapFunctional(c.default || c))
export const CommerceSlugForm = () => import('../../components/commerce/SlugForm.vue' /* webpackChunkName: "components/commerce-slug-form" */).then(c => wrapFunctional(c.default || c))
export const DashboardAppbar = () => import('../../components/dashboard/Appbar.vue' /* webpackChunkName: "components/dashboard-appbar" */).then(c => wrapFunctional(c.default || c))
export const DashboardDrawer = () => import('../../components/dashboard/Drawer.vue' /* webpackChunkName: "components/dashboard-drawer" */).then(c => wrapFunctional(c.default || c))
export const LandingAboutUsSection = () => import('../../components/landing/AboutUsSection.vue' /* webpackChunkName: "components/landing-about-us-section" */).then(c => wrapFunctional(c.default || c))
export const LandingAppbar = () => import('../../components/landing/Appbar.vue' /* webpackChunkName: "components/landing-appbar" */).then(c => wrapFunctional(c.default || c))
export const LandingBlogSection = () => import('../../components/landing/BlogSection.vue' /* webpackChunkName: "components/landing-blog-section" */).then(c => wrapFunctional(c.default || c))
export const LandingCallToActionSection = () => import('../../components/landing/CallToActionSection.vue' /* webpackChunkName: "components/landing-call-to-action-section" */).then(c => wrapFunctional(c.default || c))
export const LandingCarousel = () => import('../../components/landing/Carousel.vue' /* webpackChunkName: "components/landing-carousel" */).then(c => wrapFunctional(c.default || c))
export const LandingCommerceTypesSection = () => import('../../components/landing/CommerceTypesSection.vue' /* webpackChunkName: "components/landing-commerce-types-section" */).then(c => wrapFunctional(c.default || c))
export const LandingContactUsSection = () => import('../../components/landing/ContactUsSection.vue' /* webpackChunkName: "components/landing-contact-us-section" */).then(c => wrapFunctional(c.default || c))
export const LandingDrawer = () => import('../../components/landing/Drawer.vue' /* webpackChunkName: "components/landing-drawer" */).then(c => wrapFunctional(c.default || c))
export const LandingFaqSection = () => import('../../components/landing/FaqSection.vue' /* webpackChunkName: "components/landing-faq-section" */).then(c => wrapFunctional(c.default || c))
export const LandingFeaturesSection = () => import('../../components/landing/FeaturesSection.vue' /* webpackChunkName: "components/landing-features-section" */).then(c => wrapFunctional(c.default || c))
export const LandingFooter = () => import('../../components/landing/Footer.vue' /* webpackChunkName: "components/landing-footer" */).then(c => wrapFunctional(c.default || c))
export const LandingHeroSection = () => import('../../components/landing/HeroSection.vue' /* webpackChunkName: "components/landing-hero-section" */).then(c => wrapFunctional(c.default || c))
export const LandingHowDoesItWorkSection = () => import('../../components/landing/HowDoesItWorkSection.vue' /* webpackChunkName: "components/landing-how-does-it-work-section" */).then(c => wrapFunctional(c.default || c))
export const LandingHowMuchSection = () => import('../../components/landing/HowMuchSection.vue' /* webpackChunkName: "components/landing-how-much-section" */).then(c => wrapFunctional(c.default || c))
export const LandingMaps = () => import('../../components/landing/Maps.vue' /* webpackChunkName: "components/landing-maps" */).then(c => wrapFunctional(c.default || c))
export const LandingPartnersSection = () => import('../../components/landing/PartnersSection.vue' /* webpackChunkName: "components/landing-partners-section" */).then(c => wrapFunctional(c.default || c))
export const LandingPricingSection = () => import('../../components/landing/PricingSection.vue' /* webpackChunkName: "components/landing-pricing-section" */).then(c => wrapFunctional(c.default || c))
export const LandingStartingSection = () => import('../../components/landing/StartingSection.vue' /* webpackChunkName: "components/landing-starting-section" */).then(c => wrapFunctional(c.default || c))
export const LandingTestimonialSection = () => import('../../components/landing/TestimonialSection.vue' /* webpackChunkName: "components/landing-testimonial-section" */).then(c => wrapFunctional(c.default || c))
export const LandingWhyChooseUsSection = () => import('../../components/landing/WhyChooseUsSection.vue' /* webpackChunkName: "components/landing-why-choose-us-section" */).then(c => wrapFunctional(c.default || c))
export const LandingWorkWithUsSection = () => import('../../components/landing/WorkWithUsSection.vue' /* webpackChunkName: "components/landing-work-with-us-section" */).then(c => wrapFunctional(c.default || c))
export const LandingYoutubeDialog = () => import('../../components/landing/YoutubeDialog.vue' /* webpackChunkName: "components/landing-youtube-dialog" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceAppbar = () => import('../../components/marketplace/Appbar.vue' /* webpackChunkName: "components/marketplace-appbar" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceBannerSlider = () => import('../../components/marketplace/BannerSlider.vue' /* webpackChunkName: "components/marketplace-banner-slider" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceDrawer = () => import('../../components/marketplace/Drawer.vue' /* webpackChunkName: "components/marketplace-drawer" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceFooter = () => import('../../components/marketplace/Footer.vue' /* webpackChunkName: "components/marketplace-footer" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceMainCarousel = () => import('../../components/marketplace/MainCarousel.vue' /* webpackChunkName: "components/marketplace-main-carousel" */).then(c => wrapFunctional(c.default || c))
export const NeighborhoodGroupForm = () => import('../../components/neighborhood-group/Form.vue' /* webpackChunkName: "components/neighborhood-group-form" */).then(c => wrapFunctional(c.default || c))
export const NeighborhoodGroupList = () => import('../../components/neighborhood-group/List.vue' /* webpackChunkName: "components/neighborhood-group-list" */).then(c => wrapFunctional(c.default || c))
export const NeighborhoodGroupListItem = () => import('../../components/neighborhood-group/ListItem.vue' /* webpackChunkName: "components/neighborhood-group-list-item" */).then(c => wrapFunctional(c.default || c))
export const NeighborhoodGroupListOld = () => import('../../components/neighborhood-group/ListOld.vue' /* webpackChunkName: "components/neighborhood-group-list-old" */).then(c => wrapFunctional(c.default || c))
export const NeighborhoodGroupManagerOld = () => import('../../components/neighborhood-group/ManagerOld.vue' /* webpackChunkName: "components/neighborhood-group-manager-old" */).then(c => wrapFunctional(c.default || c))
export const OnboardingAppbar = () => import('../../components/onboarding/Appbar.vue' /* webpackChunkName: "components/onboarding-appbar" */).then(c => wrapFunctional(c.default || c))
export const OnboardingHeader = () => import('../../components/onboarding/Header.vue' /* webpackChunkName: "components/onboarding-header" */).then(c => wrapFunctional(c.default || c))
export const OperationStatisticsCard = () => import('../../components/operation/StatisticsCard.vue' /* webpackChunkName: "components/operation-statistics-card" */).then(c => wrapFunctional(c.default || c))
export const OperationTable = () => import('../../components/operation/Table.vue' /* webpackChunkName: "components/operation-table" */).then(c => wrapFunctional(c.default || c))
export const OrderFilters = () => import('../../components/order/Filters.vue' /* webpackChunkName: "components/order-filters" */).then(c => wrapFunctional(c.default || c))
export const OrderInvoiceForm = () => import('../../components/order/InvoiceForm.vue' /* webpackChunkName: "components/order-invoice-form" */).then(c => wrapFunctional(c.default || c))
export const OrderKanbanCard = () => import('../../components/order/KanbanCard.vue' /* webpackChunkName: "components/order-kanban-card" */).then(c => wrapFunctional(c.default || c))
export const OrderNavigation = () => import('../../components/order/Navigation.vue' /* webpackChunkName: "components/order-navigation" */).then(c => wrapFunctional(c.default || c))
export const OrderProductsTable = () => import('../../components/order/ProductsTable.vue' /* webpackChunkName: "components/order-products-table" */).then(c => wrapFunctional(c.default || c))
export const OrderRequiredDocumentationAlert = () => import('../../components/order/RequiredDocumentationAlert.vue' /* webpackChunkName: "components/order-required-documentation-alert" */).then(c => wrapFunctional(c.default || c))
export const OrderSeeker = () => import('../../components/order/Seeker.vue' /* webpackChunkName: "components/order-seeker" */).then(c => wrapFunctional(c.default || c))
export const OrderShippingGuideForm = () => import('../../components/order/ShippingGuideForm.vue' /* webpackChunkName: "components/order-shipping-guide-form" */).then(c => wrapFunctional(c.default || c))
export const OrderTable = () => import('../../components/order/Table.vue' /* webpackChunkName: "components/order-table" */).then(c => wrapFunctional(c.default || c))
export const PaymentGatewayWebpayForm = () => import('../../components/payment-gateway/WebpayForm.vue' /* webpackChunkName: "components/payment-gateway-webpay-form" */).then(c => wrapFunctional(c.default || c))
export const ProductBasicDataForm = () => import('../../components/product/BasicDataForm.vue' /* webpackChunkName: "components/product-basic-data-form" */).then(c => wrapFunctional(c.default || c))
export const ProductBatchImport = () => import('../../components/product/BatchImport.vue' /* webpackChunkName: "components/product-batch-import" */).then(c => wrapFunctional(c.default || c))
export const ProductBatchSuccess = () => import('../../components/product/BatchSuccess.vue' /* webpackChunkName: "components/product-batch-success" */).then(c => wrapFunctional(c.default || c))
export const ProductCard = () => import('../../components/product/Card.vue' /* webpackChunkName: "components/product-card" */).then(c => wrapFunctional(c.default || c))
export const ProductCardShopping = () => import('../../components/product/CardShopping.vue' /* webpackChunkName: "components/product-card-shopping" */).then(c => wrapFunctional(c.default || c))
export const ProductCardShoppingLandscape = () => import('../../components/product/CardShoppingLandscape.vue' /* webpackChunkName: "components/product-card-shopping-landscape" */).then(c => wrapFunctional(c.default || c))
export const ProductFilters = () => import('../../components/product/Filters.vue' /* webpackChunkName: "components/product-filters" */).then(c => wrapFunctional(c.default || c))
export const ProductImageForm = () => import('../../components/product/ImageForm.vue' /* webpackChunkName: "components/product-image-form" */).then(c => wrapFunctional(c.default || c))
export const ProductInformationForm = () => import('../../components/product/InformationForm.vue' /* webpackChunkName: "components/product-information-form" */).then(c => wrapFunctional(c.default || c))
export const ProductPropertiesForm = () => import('../../components/product/PropertiesForm.vue' /* webpackChunkName: "components/product-properties-form" */).then(c => wrapFunctional(c.default || c))
export const ProductRangeSlider = () => import('../../components/product/RangeSlider.vue' /* webpackChunkName: "components/product-range-slider" */).then(c => wrapFunctional(c.default || c))
export const ProductSalePricesForm = () => import('../../components/product/SalePricesForm.vue' /* webpackChunkName: "components/product-sale-prices-form" */).then(c => wrapFunctional(c.default || c))
export const ProductSaleRulesForm = () => import('../../components/product/SaleRulesForm.vue' /* webpackChunkName: "components/product-sale-rules-form" */).then(c => wrapFunctional(c.default || c))
export const ProductSaveDialog = () => import('../../components/product/SaveDialog.vue' /* webpackChunkName: "components/product-save-dialog" */).then(c => wrapFunctional(c.default || c))
export const ProductStatusForm = () => import('../../components/product/StatusForm.vue' /* webpackChunkName: "components/product-status-form" */).then(c => wrapFunctional(c.default || c))
export const ProductVariantsForm = () => import('../../components/product/VariantsForm.vue' /* webpackChunkName: "components/product-variants-form" */).then(c => wrapFunctional(c.default || c))
export const ProductCategoryBanners = () => import('../../components/product-category/Banners.vue' /* webpackChunkName: "components/product-category-banners" */).then(c => wrapFunctional(c.default || c))
export const ProductRepositoryFinder = () => import('../../components/product-repository/Finder.vue' /* webpackChunkName: "components/product-repository-finder" */).then(c => wrapFunctional(c.default || c))
export const ProductRepositorySuccessDialog = () => import('../../components/product-repository/SuccessDialog.vue' /* webpackChunkName: "components/product-repository-success-dialog" */).then(c => wrapFunctional(c.default || c))
export const ProductRepositorySummaryDialog = () => import('../../components/product-repository/SummaryDialog.vue' /* webpackChunkName: "components/product-repository-summary-dialog" */).then(c => wrapFunctional(c.default || c))
export const ProductRepositoryTable = () => import('../../components/product-repository/Table.vue' /* webpackChunkName: "components/product-repository-table" */).then(c => wrapFunctional(c.default || c))
export const PurchaseTable = () => import('../../components/purchase/Table.vue' /* webpackChunkName: "components/purchase-table" */).then(c => wrapFunctional(c.default || c))
export const UiAlertDialog = () => import('../../components/ui/AlertDialog.vue' /* webpackChunkName: "components/ui-alert-dialog" */).then(c => wrapFunctional(c.default || c))
export const UiCardList = () => import('../../components/ui/CardList.vue' /* webpackChunkName: "components/ui-card-list" */).then(c => wrapFunctional(c.default || c))
export const UiEmptyState = () => import('../../components/ui/EmptyState.vue' /* webpackChunkName: "components/ui-empty-state" */).then(c => wrapFunctional(c.default || c))
export const UiHeading = () => import('../../components/ui/Heading.vue' /* webpackChunkName: "components/ui-heading" */).then(c => wrapFunctional(c.default || c))
export const UiInputNumber = () => import('../../components/ui/InputNumber.vue' /* webpackChunkName: "components/ui-input-number" */).then(c => wrapFunctional(c.default || c))
export const UiNavigationMenu = () => import('../../components/ui/NavigationMenu.vue' /* webpackChunkName: "components/ui-navigation-menu" */).then(c => wrapFunctional(c.default || c))
export const UiNavigationTabs = () => import('../../components/ui/NavigationTabs.vue' /* webpackChunkName: "components/ui-navigation-tabs" */).then(c => wrapFunctional(c.default || c))
export const UiPageHeader = () => import('../../components/ui/PageHeader.vue' /* webpackChunkName: "components/ui-page-header" */).then(c => wrapFunctional(c.default || c))
export const UiPageOrderHeader = () => import('../../components/ui/PageOrderHeader.vue' /* webpackChunkName: "components/ui-page-order-header" */).then(c => wrapFunctional(c.default || c))
export const UiPagination = () => import('../../components/ui/Pagination.vue' /* webpackChunkName: "components/ui-pagination" */).then(c => wrapFunctional(c.default || c))
export const UiQuantifier = () => import('../../components/ui/Quantifier.vue' /* webpackChunkName: "components/ui-quantifier" */).then(c => wrapFunctional(c.default || c))
export const UiResourceDialog = () => import('../../components/ui/ResourceDialog.vue' /* webpackChunkName: "components/ui-resource-dialog" */).then(c => wrapFunctional(c.default || c))
export const UiSection = () => import('../../components/ui/Section.vue' /* webpackChunkName: "components/ui-section" */).then(c => wrapFunctional(c.default || c))
export const UiStatistic = () => import('../../components/ui/Statistic.vue' /* webpackChunkName: "components/ui-statistic" */).then(c => wrapFunctional(c.default || c))
export const UiSvgPlaceholder = () => import('../../components/ui/SvgPlaceholder.vue' /* webpackChunkName: "components/ui-svg-placeholder" */).then(c => wrapFunctional(c.default || c))
export const UiWhatsappIcon = () => import('../../components/ui/WhatsappIcon.vue' /* webpackChunkName: "components/ui-whatsapp-icon" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceBranchMinimumSale = () => import('../../components/marketplace/branch/MinimumSale.vue' /* webpackChunkName: "components/marketplace-branch-minimum-sale" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceBranchSelectorDialog = () => import('../../components/marketplace/branch/SelectorDialog.vue' /* webpackChunkName: "components/marketplace-branch-selector-dialog" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceBranchSlider = () => import('../../components/marketplace/branch/Slider.vue' /* webpackChunkName: "components/marketplace-branch-slider" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceFiltersEssentialSwitch = () => import('../../components/marketplace/filters/EssentialSwitch.vue' /* webpackChunkName: "components/marketplace-filters-essential-switch" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceFiltersOfferSwitch = () => import('../../components/marketplace/filters/OfferSwitch.vue' /* webpackChunkName: "components/marketplace-filters-offer-switch" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceFiltersPanel = () => import('../../components/marketplace/filters/Panel.vue' /* webpackChunkName: "components/marketplace-filters-panel" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceFiltersPriceRange = () => import('../../components/marketplace/filters/PriceRange.vue' /* webpackChunkName: "components/marketplace-filters-price-range" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceFiltersProductBrand = () => import('../../components/marketplace/filters/ProductBrand.vue' /* webpackChunkName: "components/marketplace-filters-product-brand" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceFiltersProductCategory = () => import('../../components/marketplace/filters/ProductCategory.vue' /* webpackChunkName: "components/marketplace-filters-product-category" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceFiltersSaleFormat = () => import('../../components/marketplace/filters/SaleFormat.vue' /* webpackChunkName: "components/marketplace-filters-sale-format" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceFiltersSeeker = () => import('../../components/marketplace/filters/Seeker.vue' /* webpackChunkName: "components/marketplace-filters-seeker" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceFiltersSortbyMenu = () => import('../../components/marketplace/filters/SortbyMenu.vue' /* webpackChunkName: "components/marketplace-filters-sortby-menu" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceOrderMinimumPurchaseIndicator = () => import('../../components/marketplace/order/MinimumPurchaseIndicator.vue' /* webpackChunkName: "components/marketplace-order-minimum-purchase-indicator" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceProductAddedCard = () => import('../../components/marketplace/product/AddedCard.vue' /* webpackChunkName: "components/marketplace-product-added-card" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceProductBaseCard = () => import('../../components/marketplace/product/BaseCard.vue' /* webpackChunkName: "components/marketplace-product-base-card" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceProductBaseLandscapeCard = () => import('../../components/marketplace/product/BaseLandscapeCard.vue' /* webpackChunkName: "components/marketplace-product-base-landscape-card" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceProductCard = () => import('../../components/marketplace/product/Card.vue' /* webpackChunkName: "components/marketplace-product-card" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceProductLandscapeCard = () => import('../../components/marketplace/product/LandscapeCard.vue' /* webpackChunkName: "components/marketplace-product-landscape-card" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceProductSlider = () => import('../../components/marketplace/product/Slider.vue' /* webpackChunkName: "components/marketplace-product-slider" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceProductBrandSlider = () => import('../../components/marketplace/product-brand/Slider.vue' /* webpackChunkName: "components/marketplace-product-brand-slider" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceProductCategoryDrawer = () => import('../../components/marketplace/product-category/Drawer.vue' /* webpackChunkName: "components/marketplace-product-category-drawer" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceProductCategoryDropdown = () => import('../../components/marketplace/product-category/Dropdown.vue' /* webpackChunkName: "components/marketplace-product-category-dropdown" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceProductCategoryPopular = () => import('../../components/marketplace/product-category/Popular.vue' /* webpackChunkName: "components/marketplace-product-category-popular" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceProductVariantAlternativeCard = () => import('../../components/marketplace/product-variant/AlternativeCard.vue' /* webpackChunkName: "components/marketplace-product-variant-alternative-card" */).then(c => wrapFunctional(c.default || c))
export const ProductComparatorCard = () => import('../../components/product/comparator/Card.vue' /* webpackChunkName: "components/product-comparator-card" */).then(c => wrapFunctional(c.default || c))
export const ProductComparatorRow = () => import('../../components/product/comparator/Row.vue' /* webpackChunkName: "components/product-comparator-row" */).then(c => wrapFunctional(c.default || c))
export const ProductComparatorTable = () => import('../../components/product/comparator/Table.vue' /* webpackChunkName: "components/product-comparator-table" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
