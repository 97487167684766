
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisibleCommerceList: false,
    }
  },
  computed: mapGetters({
    commerces: 'commerce/commerceWithCollaborations',
  }),
  methods: {
    ...mapActions(['changeCart']),
    ...mapMutations({
      setOrders: 'cart/SET_ORDERS',
    }),
    handleCloseCommerceList(value) {
      if (!value) this.isVisibleCommerceList = false
    },
    async handleLogout() {
      try {
        await this.$auth.logout()

        await this.changeCart({ cartId: null })
        await this.setOrders([])

        this.$auth.$storage.removeUniversal('commerce_id')
        this.$auth.$storage.removeUniversal('branch_id')
        this.$auth.$storage.removeUniversal('cart_id')
        this.$auth.$storage.removeUniversal('cart')
        this.$auth.$storage.removeUniversal('location')
        this.$auth.$storage.removeUniversal('shippingAddress')

        await this.$router.push(this.localeRoute({ name: 'login' }))
      } catch (e) {}
    },
  },
}
