
export default {
  name: 'CheckoutLayout',
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  head() {
    return {
      title: this.$t(`page_title.${this.error.statusCode}`),
    }
  },
}
