export const state = () => ({
  currentCommerceId: null,
  currentBranchId: null,
  currentCartId: null,
})

export const getters = {
  currentCommerce: (state, getters, rootState, rootGetter) => {
    return rootGetter['commerce/commerceWithCollaborations'].find(
      ({ id }) => id === state.currentCommerceId
    )
  },
  currentBranch: (state) => {
    return state.branch.branches.find(({ id }) => id === state.currentBranchId)
  },
  currentCartId: (state) => {
    return state.currentCartId
  },
  isCommerceOwner: (state, getters, rootState) => {
    return getters.currentCommerce.user_id === rootState.auth.user.id
  },
}

export const mutations = {
  SET_CURRENT_COMMERCE_ID: (state, { commerceId }) => {
    state.currentCommerceId = commerceId
  },
  SET_CURRENT_BRANCH_ID: (state, { branchId }) => {
    state.currentBranchId = branchId
  },
  SET_CURRENT_CART_ID: (state, { cartId }) => {
    state.currentCartId = cartId
  },
}

export const actions = {
  changeCommerce({ commit }, { commerceId }) {
    this.$auth.$storage.setUniversal('commerce_id', commerceId)

    commit('SET_CURRENT_COMMERCE_ID', { commerceId })
  },
  changeBranch({ commit }, { branchId }) {
    this.$auth.$storage.setUniversal('branch_id', branchId)

    commit('SET_CURRENT_BRANCH_ID', { branchId })
  },
  changeCart({ commit }, { cartId }) {
    commit('SET_CURRENT_CART_ID', { cartId })
  },
}
