import { render, staticRenderFns } from "./Drawer.vue?vue&type=template&id=1503136d&"
import script from "./Drawer.vue?vue&type=script&lang=js&"
export * from "./Drawer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AccountNotificationDropdown: require('/workspace/components/account/NotificationDropdown.vue').default,AccountCartDropdown: require('/workspace/components/account/CartDropdown.vue').default,UiSvgPlaceholder: require('/workspace/components/ui/SvgPlaceholder.vue').default})
