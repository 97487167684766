export default ({ $toast }, inject) => {
  const options = {
    action: {
      icon: 'close-circle',
      onClick: (e, toastObject) => toastObject.goAway(0),
    },
  }

  inject('notification', {
    success: (message) => $toast.success(message, options),
    error: (message) => $toast.error(message, options),
    info: (message) => $toast.info(message, options),
  })
}
