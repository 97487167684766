
export default {
  name: 'DefaultLayout',
  data() {
    return {
      drawerVisible: false,
    }
  },
  methods: {
    handleToggle() {
      this.drawerVisible = !this.drawerVisible
    },
  },
}
