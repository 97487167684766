const middleware = {}

middleware['branchLoader'] = require('../middleware/branchLoader.js')
middleware['branchLoader'] = middleware['branchLoader'].default || middleware['branchLoader']

middleware['branchRequired'] = require('../middleware/branchRequired.js')
middleware['branchRequired'] = middleware['branchRequired'].default || middleware['branchRequired']

middleware['commerceLoader'] = require('../middleware/commerceLoader.js')
middleware['commerceLoader'] = middleware['commerceLoader'].default || middleware['commerceLoader']

middleware['hasCommerces'] = require('../middleware/hasCommerces.js')
middleware['hasCommerces'] = middleware['hasCommerces'].default || middleware['hasCommerces']

middleware['hasCurrentCity'] = require('../middleware/hasCurrentCity.js')
middleware['hasCurrentCity'] = middleware['hasCurrentCity'].default || middleware['hasCurrentCity']

middleware['isCommerceTypeWhosealer'] = require('../middleware/isCommerceTypeWhosealer.js')
middleware['isCommerceTypeWhosealer'] = middleware['isCommerceTypeWhosealer'].default || middleware['isCommerceTypeWhosealer']

middleware['permissionsLoader'] = require('../middleware/permissionsLoader.js')
middleware['permissionsLoader'] = middleware['permissionsLoader'].default || middleware['permissionsLoader']

export default middleware
