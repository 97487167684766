export default async ({ store, $auth }) => {
  try {
    if ($auth.loggedIn) {
      await store.dispatch('branch/fetchBranches', {
        commerceId: store.state.currentCommerceId,
        params: { per_page: 100 },
      });

      const branchIdStorage = $auth.$storage.getUniversal('branch_id');

      if (!branchIdStorage && store.getters['branch/branches'].length) {
        const firstBranchId = store.getters['branch/branches'][0]?.id;
        $auth.$storage.setUniversal('branch_id', firstBranchId);
      }

      const selectedBranchId = $auth.$storage.getUniversal('branch_id');
      store.dispatch('changeBranch', { branchId: selectedBranchId });
    }
  } catch (error) {
    console.error(error);
  }
};
