export const state = () => ({
  roles: [],
})

export const getters = {
  roles: (state) => state.roles,
}

export const mutations = {
  SET_ROLES: (state, { roles }) => (state.roles = roles),
}

export const actions = {
  async fetchRoles({ commit, rootState }, { commerceId, params = {} }) {
    return await this.$axios
      .$get(`/api/v1/private/commerces/${commerceId}/roles`, { params })
      .then(({ roles, meta }) => {
        commit('SET_ROLES', { roles })

        return { roles, meta }
      })
  },
}
