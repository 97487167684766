export const state = () => ({
  productBrands: [],
  pagination: {},
})

export const getters = {
  productBrands: (state) => state.productBrands,
  productBrandById:
    (state) =>
      ({ productBrandId }) =>
        state.productBrands.find(
          (productBrand) => productBrand.id === productBrandId
        ),
  pagination: (state) => state.pagination,
}

export const mutations = {
  SET_PRODUCT_BRANDS: (state, { productBrands }) =>
    (state.productBrands = productBrands),
  ADD_PRODUCT_BRAND: (state, { productBrand }) =>
    state.productBrands.unshift(productBrand),
  UPDATE_PRODUCT_BRAND: (state, { productBrand }) => {
    const productBrandFound = state.productBrands.findIndex(
      ({ id }) => id === productBrand.id
    )

    if (productBrandFound === -1) return

    state.productBrands.splice(productBrandFound, 1, productBrand)
  },
  REMOVE_PRODUCT_BRAND: (state, { productBrandId }) => {
    const productBrandFound = state.productBrands.findIndex(
      ({ id }) => id === productBrandId
    )

    if (productBrandFound === -1) return

    state.productBrands.splice(productBrandFound, 1)
  },
  SET_PAGINATION: (state, { meta }) => (state.pagination = meta),
}

export const actions = {
  async fetchProductBrands({ commit }, { params = {} } = {}) {
    return await this.$axios
      .$get(`/api/v1/public/product-brands`, {
        params,
      })
      .then(({ product_brands: productBrands, meta }) => {
        commit('SET_PRODUCT_BRANDS', { productBrands })
        commit('SET_PAGINATION', { meta })

        return { productBrands, meta }
      })
  },
  async fetchProductBrand({ commit, getters }, { productBrandId }) {
    return await this.$axios
      .$get(`/api/v1/public/product-brands/${productBrandId}`)
      .then(({ product_brand: productBrand }) => {
        getters.productBrandById({ productBrandId })
          ? commit('UPDATE_PRODUCT_BRAND', { productBrand })
          : commit('ADD_PRODUCT_BRAND', { productBrand })

        return { productBrand }
      })
  },
  addProductBrand({ commit, getters }, { productBrand }) {
    getters.productBrandById({ productBrandId: productBrand.id })
      ? commit('UPDATE_PRODUCT_BRAND', { productBrand })
      : commit('ADD_PRODUCT_BRAND', { productBrand })
  }
}
