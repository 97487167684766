
import { mapGetters } from 'vuex'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      currentBranch: 'currentBranch',
    }),
  },
  methods: {
    handleChangeBranch() {
      this.$emit('update:visible', false)
      this.$emit('branch-change', true)
    },
    handleClose() {
      this.$emit('update:visible', false)
    },
    async handleLogout() {
      try {
        await this.$auth.logout()

        await this.changeCart({ cartId: null })
        await this.setOrders([])

        this.$auth.$storage.removeUniversal('commerce_id')
        this.$auth.$storage.removeUniversal('branch_id')
        this.$auth.$storage.removeUniversal('cart_id')
        this.$auth.$storage.removeUniversal('cart')
        this.$auth.$storage.removeUniversal('location')
        this.$auth.$storage.removeUniversal('shippingAddress')

        await this.$router.push(this.localeRoute({ name: 'login' }))
      } catch (e) {}
    },
  },
}
