
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      currentCommerce: 'currentCommerce',
      currentBranch: 'currentBranch',
      branches: 'branch/branches',
    }),
    branchOptions() {
      return this.branches.map((branch) => ({
        text: branch.name,
        value: branch.id,
      }))
    },
    isSelected() {
      return !!this.currentBranch
    },
    isRetailer() {
      return this.currentCommerce.commerce_type === 'retailer'
    },
  },
  methods: {
    ...mapActions({
      changeBranch: 'changeBranch',
    }),
    handleChangeBranch(branchId) {
      if (this.currentBranch?.id === branchId) return

      this.changeBranch({ branchId })

      this.$notification.info(this.$t('message.branch_change_success'))
    },
  },
}
