export const state = () => ({
  commerces: [],
  collaborations: [],
  pagination: {
    current_page: 1,
    per_page: 12,
    last_page: 0,
    total: 0,
  },
})

export const getters = {
  commerces: (state) => state.commerces,
  collaborations: (state) =>
    state.collaborations.map((collaboration) => ({
      ...collaboration,
      collaboration: true,
    })),
  commerceWithCollaborations: (state, getters) =>
    state.commerces.concat(getters.collaborations),
  commerceById:
    (state) =>
    ({ commerceId }) =>
      state.commerces.find((commerce) => commerce.id === commerceId),
  commerceByUserId:
    (state) =>
    ({ commerceId, userId }) =>
      state.commerces.find(
        (commerce) => commerce.id === commerceId && commerce.user_id === userId
      ),
  pagination: (state) => state.pagination,
}

export const mutations = {
  SET_COMMERCES: (state, { commerces }) => (state.commerces = commerces),
  SET_COLLABORATIONS: (state, { commerces }) =>
    (state.collaborations = commerces),
  ADD_COMMERCE: (state, { commerce }) => state.commerces.unshift(commerce),
  UPDATE_COMMERCE: (state, { commerce }) => {
    const commerceFound = state.commerces.findIndex(
      ({ id }) => id === commerce.id
    )

    if (commerceFound === -1) return

    state.commerces.splice(commerceFound, 1, commerce)
  },
  REMOVE_COMMERCE: (state, { commerceId }) => {
    const commerceFound = state.commerces.findIndex(
      ({ id }) => id === commerceId
    )

    if (commerceFound === -1) return

    state.commerces.splice(commerceFound, 1)
  },
  SET_PAGINATION: (state, { meta }) => (state.pagination = meta),
}

export const actions = {
  async fetchCommerces({ commit }, { params = {} } = {}) {
    return await this.$axios
      .$get('/api/v1/private/commerces', { params })
      .then(({ commerces, meta }) => {
        commit('SET_COMMERCES', { commerces })
        commit('SET_PAGINATION', { meta })

        return { commerces, meta }
      })
  },
  async fetchCollaborations({ commit }, { params = {} } = {}) {
    return await this.$axios
      .$get('/api/v1/private/collaborators/collaborator-commerces', { params })
      .then(({ commerces, meta }) => {
        commit('SET_COLLABORATIONS', { commerces })

        return { commerces, meta }
      })
  },
  async fetchCommerce({ commit, getters }, { commerceId }) {
    return await this.$axios
      .$get(`/api/v1/private/commerces/${commerceId}`)
      .then(({ commerce }) => {
        getters.commerceById({ commerceId })
          ? commit('UPDATE_COMMERCE', { commerce })
          : commit('ADD_COMMERCE', { commerce })

        return { commerce }
      })
  },
  async createCommerce({ commit, getters }, { data }) {
    return await this.$axios
      .$post('/api/v1/private/commerces', data)
      .then(({ commerce }) => {
        commit('ADD_COMMERCE', { commerce })

        return { commerce }
      })
  },
  async updateCommerce({ commit, getters }, { commerceId, data }) {
    return await this.$axios
      .$put(`/api/v1/private/commerces/${commerceId}`, data)
      .then(({ commerce }) => {
        commit('UPDATE_COMMERCE', { commerce })

        return { commerce }
      })
  },
  async deleteCommerce({ commit, getters }, { commerceId }) {
    return await this.$axios
      .$delete(`/api/v1/private/commerces/${commerceId}`)
      .then(() => {
        commit('REMOVE_COMMERCE', { commerceId })
      })
  },
}
