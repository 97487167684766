export default function ({ app }) {
  return {
    theme: {
      options: { customProperties: true },
      dark: false,
      themes: {
        light: {
          primary: {
            lighten5: '#e9e5f5',
            lighten4: '#c8bfe6',
            lighten3: '#a495d5',
            lighten2: '#7f6ac4',
            lighten1: '#634ab7',
            base: '#482aaa',
            darken1: '#4125a3',
            darken2: '#381f99',
            darken3: '#301990',
            darken4: '#210f7f',
            accent1: '#bcb2ff',
            accent2: '#8f7fff',
            accent3: '#634cff',
            accent4: '#4d32ff',
          },
          secondary: {
            lighten5: '#fae3ef',
            lighten4: '#f3bad8',
            lighten3: '#eb8cbe',
            lighten2: '#e35da4',
            lighten1: '#dd3b91',
            base: '#d7187d',
            darken1: '#d31575',
            darken2: '#cd116a',
            darken3: '#c70e60',
            darken4: '#be084d',
            accent1: '#ffe8f0',
            accent2: '#ffb5cd',
            accent3: '#ff82ab',
            accent4: '#ff699a',
          },
        },
      },
    },
    lang: {
      t: (key, ...params) => app.i18n.t(key, params),
    },
  }
}
