
export default {
  props: {
    file: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '25%',
    },
    color: {
      type: String,
      default: 'primary--text text--darken-5',
    },
    backgroundColor: {
      type: String,
      default: 'primary lighten-5',
    },
  },
}
