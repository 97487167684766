export const locationDecoder = (place) => {
  const address = place.formatted_address
  let city = null
  let state = null
  const latitude = place.geometry.location.lat()
  const longitude = place.geometry.location.lng()

  for (const component of place.address_components) {
    const componentType = component.types[0]

    switch (componentType) {
      case 'administrative_area_level_3':
        city = component.long_name
          .toLowerCase()
          .normalize('NFD') // Descomponer caracteres diacríticos
          .replace(/[\u0300-\u036F]/g, '') // Eliminar diacríticos
          .replace(/\s+/g, '-'); // Reemplazar espacios con '-'
        break;

      case 'administrative_area_level_1':
        state = component.long_name
        break;
    }
  }

  return {
    address,
    city,
    state,
    latitude,
    longitude
  }
}