export const state = () => ({
  properties: [],
  pagination: {},
})

export const getters = {
  properties: (state) => state.properties,
  propertyById:
    (state) =>
    ({ propertyId }) =>
      state.properties.find((property) => property.id === propertyId),
  pagination: (state) => state.pagination,
}

export const mutations = {
  SET_PROPERTIES: (state, { properties }) => (state.properties = properties),
  ADD_PROPERTY: (state, { property }) => state.properties.unshift(property),
  UPDATE_PROPERTY: (state, { property }) => {
    const propertyFound = state.properties.findIndex(
      ({ id }) => id === property.id
    )

    if (propertyFound === -1) return

    state.properties.splice(propertyFound, 1, property)
  },
  REMOVE_PROPERTY: (state, { propertyId }) => {
    const propertyFound = state.properties.findIndex(
      ({ id }) => id === propertyId
    )

    if (propertyFound === -1) return

    state.properties.splice(propertyFound, 1)
  },
  SET_PAGINATION: (state, { meta }) => (state.pagination = meta),
}

export const actions = {
  async fetchProperties({ commit }, { params = {} } = {}) {
    return await this.$axios
      .$get(`/api/v1/public/properties`, { params })
      .then(({ properties, meta }) => {
        commit('SET_PROPERTIES', { properties })
        commit('SET_PAGINATION', { meta })

        return { properties, meta }
      })
  },
  async fetchProperty({ commit, getters }, { propertyId }) {
    return await this.$axios
      .$get(`/api/v1/public/properties/${propertyId}`)
      .then(({ property }) => {
        getters.propertyById({ propertyId })
          ? commit('UPDATE_PROPERTY', { property })
          : commit('ADD_PROPERTY', { property })

        return { property }
      })
  },
}
