export const state = () => ({
  operations: [],
  pagination: {
    current_page: 1,
    per_page: 12,
    last_page: 0,
    total: 0,
  },
})

export const getters = {
  operations: (state) => state.operations,
  operationById:
    (state) =>
    ({ operationId }) =>
      state.operations.find((operation) => operation.id === operationId),
  pagination: (state) => state.pagination,
}

export const mutations = {
  SET_OPERATIONS: (state, { operations }) => (state.operations = operations),
  ADD_OPERATION: (state, { operation }) => state.operations.unshift(operation),
  UPDATE_OPERATION: (state, { operation }) => {
    const operationFound = state.operations.findIndex(
      ({ id }) => id === operation.id
    )

    if (operationFound === -1) return

    state.operations.splice(operationFound, 1, operation)
  },
  REMOVE_OPERATION: (state, { operationId }) => {
    const operationFound = state.operations.findIndex(
      ({ id }) => id === operationId
    )

    if (operationFound === -1) return

    state.operations.splice(operationFound, 1)
  },
  SET_PAGINATION: (state, { meta }) => (state.pagination = meta),
}

export const actions = {
  async fetchOperations({ commit }, { branchId, params = {} }) {
    return await this.$axios
      .$get(`/api/v1/private/branches/${branchId}/branch-operations`, {
        params,
      })
      .then(({ operations, meta }) => {
        commit('SET_OPERATIONS', { operations })
        commit('SET_PAGINATION', { meta })

        return { operations, meta }
      })
  },
}
