import { configure } from 'vee-validate'

export default ({ app }) => {
  app.vuetify.framework.lang.current = app.i18n.localeProperties.code

  app.i18n.onBeforeLanguageSwitch = (oldLocale, newLocale) => {
    app.vuetify.framework.lang.current = newLocale
  }

  configure({
    defaultMessage: (field, values) => {
      return app.i18n.t(`validation.${values._rule_}`, values)
    },
  })
}
