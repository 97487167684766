
import dynamicViewport from '@/mixins/dynamicViewport'

export default {
  name: 'DashboardLayout',
  mixins: [dynamicViewport],
  middleware: [
    'commerceLoader',
    'branchLoader',
    'branchRequired',
    'permissionsLoader',
  ],
  data() {
    return {
      isVisible: true,
      isCollapsed: false,
    }
  },
  methods: {
    handleToggleVisible() {
      this.isVisible = !this.isVisible
    },
    handleToggleCollapse() {
      this.isCollapsed = !this.isCollapsed
    },
  },
}
