
export default {
  props: {
    menuItems: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleToggleDrawer() {
      this.$emit('toggle');
    },
    handleMenuItemClick(goto, externalGoto, actionLink) {
      if (goto && goto.startsWith('#')) {
        this.$vuetify.goTo(goto);
      } else if (externalGoto) {
        window.open(externalGoto, '_blank');
      }
      else if (actionLink) {
        this.$router.push(
          this.localeRoute({
            name: actionLink,
          })
        )
      }
    }
  },
};
