// store/location.js

export const state = () => ({
  location: null,
});

export const mutations = {
  SET_LOCATION: (state, { location }) => (state.location = location),
}

export const actions = {
  setLocation({ commit }, location) {
    commit('SET_LOCATION', { location })
  },
};

export const getters = {
  getLocation: (state) => state.location,
};