
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleVisible(value) {
      this.$emit('update:visible', value)
    },
    handleAttributes(item) {
      const attributes = {}

      if ('route' in item) {
        attributes.to = item.route
      }

      if ('href' in item) {
        attributes.href = item.href
      }

      return attributes
    },
    handleListener(item) {
      const listeners = {}

      if ('goTo' in item) {
        listeners.click = () => this.handleGoto(item.goTo)
      }

      return listeners
    },
    handleGoto(anchor) {
      this.$vuetify.goTo(anchor)

      this.$emit('update:visible', false)
    },
  },
}
