export default function ({ store, $auth }) {
  if (
    !$auth.$storage.getUniversal('location') &&
    !!store.getters['location/getLocation']
  ) {
    $auth.$storage.setUniversal(
      'location',
      store.getters['location/getLocation']
    )
  }
}