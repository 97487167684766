import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _40dbf02a = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _7b53489c = () => interopDefault(import('../pages/commerces.vue' /* webpackChunkName: "pages/commerces" */))
const _fdf2095e = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _12f7acbf = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _aa18caba = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _8bbafc70 = () => interopDefault(import('../pages/marketplace/index.vue' /* webpackChunkName: "pages/marketplace/index" */))
const _90b7573a = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _de6d514e = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _5b2afb61 = () => interopDefault(import('../pages/suppliers.vue' /* webpackChunkName: "pages/suppliers" */))
const _3bd78caa = () => interopDefault(import('../pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _702fc05a = () => interopDefault(import('../pages/account/commerces/index.vue' /* webpackChunkName: "pages/account/commerces/index" */))
const _2ec7db59 = () => interopDefault(import('../pages/dashboard/bank-accounts/index.vue' /* webpackChunkName: "pages/dashboard/bank-accounts/index" */))
const _75798f76 = () => interopDefault(import('../pages/dashboard/branch/index.vue' /* webpackChunkName: "pages/dashboard/branch/index" */))
const _82171a7c = () => interopDefault(import('../pages/dashboard/customize.vue' /* webpackChunkName: "pages/dashboard/customize" */))
const _689c5fc5 = () => interopDefault(import('../pages/dashboard/customize/index.vue' /* webpackChunkName: "pages/dashboard/customize/index" */))
const _c28253f0 = () => interopDefault(import('../pages/dashboard/customize/logo.vue' /* webpackChunkName: "pages/dashboard/customize/logo" */))
const _a2f5672a = () => interopDefault(import('../pages/dashboard/customize/social-network.vue' /* webpackChunkName: "pages/dashboard/customize/social-network" */))
const _5aca69d9 = () => interopDefault(import('../pages/dashboard/customize/summary.vue' /* webpackChunkName: "pages/dashboard/customize/summary" */))
const _061c54f9 = () => interopDefault(import('../pages/dashboard/orders/index.vue' /* webpackChunkName: "pages/dashboard/orders/index" */))
const _57978678 = () => interopDefault(import('../pages/dashboard/products/index.vue' /* webpackChunkName: "pages/dashboard/products/index" */))
const _5791d854 = () => interopDefault(import('../pages/dashboard/purchases/index.vue' /* webpackChunkName: "pages/dashboard/purchases/index" */))
const _6ad2b3ec = () => interopDefault(import('../pages/dashboard/shop/index.vue' /* webpackChunkName: "pages/dashboard/shop/index" */))
const _d7764acc = () => interopDefault(import('../pages/marketplace/cart.vue' /* webpackChunkName: "pages/marketplace/cart" */))
const _2c3655e1 = () => interopDefault(import('../pages/marketplace/products/index.vue' /* webpackChunkName: "pages/marketplace/products/index" */))
const _7e798539 = () => interopDefault(import('../pages/account/branches/new.vue' /* webpackChunkName: "pages/account/branches/new" */))
const _211a19c6 = () => interopDefault(import('../pages/account/commerces/collaborations.vue' /* webpackChunkName: "pages/account/commerces/collaborations" */))
const _ef70603e = () => interopDefault(import('../pages/account/commerces/new.vue' /* webpackChunkName: "pages/account/commerces/new" */))
const _06669632 = () => interopDefault(import('../pages/dashboard/bank-accounts/new.vue' /* webpackChunkName: "pages/dashboard/bank-accounts/new" */))
const _6fc92c90 = () => interopDefault(import('../pages/dashboard/branch/distribution.vue' /* webpackChunkName: "pages/dashboard/branch/distribution" */))
const _2996689a = () => interopDefault(import('../pages/dashboard/branch/sales-rules.vue' /* webpackChunkName: "pages/dashboard/branch/sales-rules" */))
const _3ccbedba = () => interopDefault(import('../pages/dashboard/branch/schedule.vue' /* webpackChunkName: "pages/dashboard/branch/schedule" */))
const _87a2e6a2 = () => interopDefault(import('../pages/dashboard/branch/settings.vue' /* webpackChunkName: "pages/dashboard/branch/settings" */))
const _6ac64da0 = () => interopDefault(import('../pages/dashboard/orders/kanban.vue' /* webpackChunkName: "pages/dashboard/orders/kanban" */))
const _1fc42d22 = () => interopDefault(import('../pages/dashboard/products/import.vue' /* webpackChunkName: "pages/dashboard/products/import" */))
const _28f61c34 = () => interopDefault(import('../pages/dashboard/products/import/csv.vue' /* webpackChunkName: "pages/dashboard/products/import/csv" */))
const _10ff70cc = () => interopDefault(import('../pages/dashboard/products/import/repository.vue' /* webpackChunkName: "pages/dashboard/products/import/repository" */))
const _cd0ac874 = () => interopDefault(import('../pages/dashboard/products/new.vue' /* webpackChunkName: "pages/dashboard/products/new" */))
const _671d3488 = () => interopDefault(import('../pages/dashboard/shop/setting.vue' /* webpackChunkName: "pages/dashboard/shop/setting" */))
const _6ba5e308 = () => interopDefault(import('../pages/dashboard/shop/social-media.vue' /* webpackChunkName: "pages/dashboard/shop/social-media" */))
const _6991cd84 = () => interopDefault(import('../pages/account/commerces/_id/index.vue' /* webpackChunkName: "pages/account/commerces/_id/index" */))
const _2dd23ffe = () => interopDefault(import('../pages/dashboard/bank-accounts/_id.vue' /* webpackChunkName: "pages/dashboard/bank-accounts/_id" */))
const _05bcbc78 = () => interopDefault(import('../pages/dashboard/bank-accounts/_id/index.vue' /* webpackChunkName: "pages/dashboard/bank-accounts/_id/index" */))
const _077e74c4 = () => interopDefault(import('../pages/dashboard/bank-accounts/_id/information.vue' /* webpackChunkName: "pages/dashboard/bank-accounts/_id/information" */))
const _3bd8afa1 = () => interopDefault(import('../pages/dashboard/bank-accounts/_id/settings.vue' /* webpackChunkName: "pages/dashboard/bank-accounts/_id/settings" */))
const _873f6938 = () => interopDefault(import('../pages/dashboard/orders/_id/index.vue' /* webpackChunkName: "pages/dashboard/orders/_id/index" */))
const _f4767240 = () => interopDefault(import('../pages/dashboard/products/_id.vue' /* webpackChunkName: "pages/dashboard/products/_id" */))
const _dc4eb53a = () => interopDefault(import('../pages/dashboard/products/_id/index.vue' /* webpackChunkName: "pages/dashboard/products/_id/index" */))
const _796855fd = () => interopDefault(import('../pages/dashboard/products/_id/information.vue' /* webpackChunkName: "pages/dashboard/products/_id/information" */))
const _c19bc726 = () => interopDefault(import('../pages/dashboard/products/_id/inventory.vue' /* webpackChunkName: "pages/dashboard/products/_id/inventory" */))
const _d73d62a0 = () => interopDefault(import('../pages/dashboard/products/_id/sale-rules.vue' /* webpackChunkName: "pages/dashboard/products/_id/sale-rules" */))
const _596713a2 = () => interopDefault(import('../pages/dashboard/products/_id/settings.vue' /* webpackChunkName: "pages/dashboard/products/_id/settings" */))
const _f342ad82 = () => interopDefault(import('../pages/dashboard/purchases/_id/index.vue' /* webpackChunkName: "pages/dashboard/purchases/_id/index" */))
const _83bc9e16 = () => interopDefault(import('../pages/marketplace/branches/_id.vue' /* webpackChunkName: "pages/marketplace/branches/_id" */))
const _05641971 = () => interopDefault(import('../pages/marketplace/brands/_id.vue' /* webpackChunkName: "pages/marketplace/brands/_id" */))
const _49e668c1 = () => interopDefault(import('../pages/marketplace/categories/_id.vue' /* webpackChunkName: "pages/marketplace/categories/_id" */))
const _0f725c89 = () => interopDefault(import('../pages/marketplace/products/_id.vue' /* webpackChunkName: "pages/marketplace/products/_id" */))
const _3bd76fce = () => interopDefault(import('../pages/account/commerces/_id/collaborators.vue' /* webpackChunkName: "pages/account/commerces/_id/collaborators" */))
const _6ff34de7 = () => interopDefault(import('../pages/account/commerces/_id/settings.vue' /* webpackChunkName: "pages/account/commerces/_id/settings" */))
const _ec63d42e = () => interopDefault(import('../pages/marketplace/item/_id/added.vue' /* webpackChunkName: "pages/marketplace/item/_id/added" */))
const _92332964 = () => interopDefault(import('../pages/dashboard/purchases/_id/orders/_order/receptions/new.vue' /* webpackChunkName: "pages/dashboard/purchases/_id/orders/_order/receptions/new" */))
const _3632c012 = () => interopDefault(import('../pages/dashboard/purchases/_id/orders/_order/receptions/_reception.vue' /* webpackChunkName: "pages/dashboard/purchases/_id/orders/_order/receptions/_reception" */))
const _34ae8bc4 = () => interopDefault(import('../pages/articles/_id.vue' /* webpackChunkName: "pages/articles/_id" */))
const _757d28df = () => interopDefault(import('../pages/invitations/_id.vue' /* webpackChunkName: "pages/invitations/_id" */))
const _45c92566 = () => interopDefault(import('../pages/password-reset/_token.vue' /* webpackChunkName: "pages/password-reset/_token" */))
const _50fe0058 = () => interopDefault(import('../pages/checkout/_id/confirm.vue' /* webpackChunkName: "pages/checkout/_id/confirm" */))
const _15464384 = () => interopDefault(import('../pages/checkout/_id/summary.vue' /* webpackChunkName: "pages/checkout/_id/summary" */))
const _3d6810e8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _40dbf02a,
    name: "account___es"
  }, {
    path: "/commerces",
    component: _7b53489c,
    name: "commerces___es"
  }, {
    path: "/dashboard",
    component: _fdf2095e,
    name: "dashboard___es"
  }, {
    path: "/forgot-password",
    component: _12f7acbf,
    name: "forgot-password___es"
  }, {
    path: "/login",
    component: _aa18caba,
    name: "login___es"
  }, {
    path: "/marketplace",
    component: _8bbafc70,
    name: "marketplace___es"
  }, {
    path: "/profile",
    component: _90b7573a,
    name: "profile___es"
  }, {
    path: "/register",
    component: _de6d514e,
    name: "register___es"
  }, {
    path: "/suppliers",
    component: _5b2afb61,
    name: "suppliers___es"
  }, {
    path: "/terms-and-conditions",
    component: _3bd78caa,
    name: "terms-and-conditions___es"
  }, {
    path: "/account/commerces",
    component: _702fc05a,
    name: "account-commerces___es"
  }, {
    path: "/dashboard/bank-accounts",
    component: _2ec7db59,
    name: "dashboard-bank-accounts___es"
  }, {
    path: "/dashboard/branch",
    component: _75798f76,
    name: "dashboard-branch___es"
  }, {
    path: "/dashboard/customize",
    component: _82171a7c,
    children: [{
      path: "",
      component: _689c5fc5,
      name: "dashboard-customize___es"
    }, {
      path: "logo",
      component: _c28253f0,
      name: "dashboard-customize-logo___es"
    }, {
      path: "social-network",
      component: _a2f5672a,
      name: "dashboard-customize-social-network___es"
    }, {
      path: "summary",
      component: _5aca69d9,
      name: "dashboard-customize-summary___es"
    }]
  }, {
    path: "/dashboard/orders",
    component: _061c54f9,
    name: "dashboard-orders___es"
  }, {
    path: "/dashboard/products",
    component: _57978678,
    name: "dashboard-products___es"
  }, {
    path: "/dashboard/purchases",
    component: _5791d854,
    name: "dashboard-purchases___es"
  }, {
    path: "/dashboard/shop",
    component: _6ad2b3ec,
    name: "dashboard-shop___es"
  }, {
    path: "/marketplace/cart",
    component: _d7764acc,
    name: "marketplace-cart___es"
  }, {
    path: "/marketplace/products",
    component: _2c3655e1,
    name: "marketplace-products___es"
  }, {
    path: "/account/branches/new",
    component: _7e798539,
    name: "account-branches-new___es"
  }, {
    path: "/account/commerces/collaborations",
    component: _211a19c6,
    name: "account-commerces-collaborations___es"
  }, {
    path: "/account/commerces/new",
    component: _ef70603e,
    name: "account-commerces-new___es"
  }, {
    path: "/dashboard/bank-accounts/new",
    component: _06669632,
    name: "dashboard-bank-accounts-new___es"
  }, {
    path: "/dashboard/branch/distribution",
    component: _6fc92c90,
    name: "dashboard-branch-distribution___es"
  }, {
    path: "/dashboard/branch/sales-rules",
    component: _2996689a,
    name: "dashboard-branch-sales-rules___es"
  }, {
    path: "/dashboard/branch/schedule",
    component: _3ccbedba,
    name: "dashboard-branch-schedule___es"
  }, {
    path: "/dashboard/branch/settings",
    component: _87a2e6a2,
    name: "dashboard-branch-settings___es"
  }, {
    path: "/dashboard/orders/kanban",
    component: _6ac64da0,
    name: "dashboard-orders-kanban___es"
  }, {
    path: "/dashboard/products/import",
    component: _1fc42d22,
    name: "dashboard-products-import___es",
    children: [{
      path: "csv",
      component: _28f61c34,
      name: "dashboard-products-import-csv___es"
    }, {
      path: "repository",
      component: _10ff70cc,
      name: "dashboard-products-import-repository___es"
    }]
  }, {
    path: "/dashboard/products/new",
    component: _cd0ac874,
    name: "dashboard-products-new___es"
  }, {
    path: "/dashboard/shop/setting",
    component: _671d3488,
    name: "dashboard-shop-setting___es"
  }, {
    path: "/dashboard/shop/social-media",
    component: _6ba5e308,
    name: "dashboard-shop-social-media___es"
  }, {
    path: "/account/commerces/:id",
    component: _6991cd84,
    name: "account-commerces-id___es"
  }, {
    path: "/dashboard/bank-accounts/:id?",
    component: _2dd23ffe,
    children: [{
      path: "",
      component: _05bcbc78,
      name: "dashboard-bank-accounts-id___es"
    }, {
      path: "information",
      component: _077e74c4,
      name: "dashboard-bank-accounts-id-information___es"
    }, {
      path: "settings",
      component: _3bd8afa1,
      name: "dashboard-bank-accounts-id-settings___es"
    }]
  }, {
    path: "/dashboard/orders/:id",
    component: _873f6938,
    name: "dashboard-orders-id___es"
  }, {
    path: "/dashboard/products/:id",
    component: _f4767240,
    children: [{
      path: "",
      component: _dc4eb53a,
      name: "dashboard-products-id___es"
    }, {
      path: "information",
      component: _796855fd,
      name: "dashboard-products-id-information___es"
    }, {
      path: "inventory",
      component: _c19bc726,
      name: "dashboard-products-id-inventory___es"
    }, {
      path: "sale-rules",
      component: _d73d62a0,
      name: "dashboard-products-id-sale-rules___es"
    }, {
      path: "settings",
      component: _596713a2,
      name: "dashboard-products-id-settings___es"
    }]
  }, {
    path: "/dashboard/purchases/:id",
    component: _f342ad82,
    name: "dashboard-purchases-id___es"
  }, {
    path: "/marketplace/branches/:id?",
    component: _83bc9e16,
    name: "marketplace-branches-id___es"
  }, {
    path: "/marketplace/brands/:id?",
    component: _05641971,
    name: "marketplace-brands-id___es"
  }, {
    path: "/marketplace/categories/:id?",
    component: _49e668c1,
    name: "marketplace-categories-id___es"
  }, {
    path: "/marketplace/products/:id",
    component: _0f725c89,
    name: "marketplace-products-id___es"
  }, {
    path: "/account/commerces/:id/collaborators",
    component: _3bd76fce,
    name: "account-commerces-id-collaborators___es"
  }, {
    path: "/account/commerces/:id/settings",
    component: _6ff34de7,
    name: "account-commerces-id-settings___es"
  }, {
    path: "/marketplace/item/:id?/added",
    component: _ec63d42e,
    name: "marketplace-item-id-added___es"
  }, {
    path: "/dashboard/purchases/:id/orders/:order?/receptions/new",
    component: _92332964,
    name: "dashboard-purchases-id-orders-order-receptions-new___es"
  }, {
    path: "/dashboard/purchases/:id/orders/:order?/receptions/:reception?",
    component: _3632c012,
    name: "dashboard-purchases-id-orders-order-receptions-reception___es"
  }, {
    path: "/articles/:id?",
    component: _34ae8bc4,
    name: "articles-id___es"
  }, {
    path: "/invitations/:id?",
    component: _757d28df,
    name: "invitations-id___es"
  }, {
    path: "/password-reset/:token?",
    component: _45c92566,
    name: "password-reset-token___es"
  }, {
    path: "/checkout/:id?/confirm",
    component: _50fe0058,
    name: "checkout-id-confirm___es"
  }, {
    path: "/checkout/:id?/summary",
    component: _15464384,
    name: "checkout-id-summary___es"
  }, {
    path: "/",
    component: _3d6810e8,
    name: "index___es"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
