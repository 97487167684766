import { render, staticRenderFns } from "./account.vue?vue&type=template&id=ac6ac4cc&"
import script from "./account.vue?vue&type=script&lang=js&"
export * from "./account.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AccountDrawer: require('/workspace/components/account/Drawer.vue').default,AccountAppbar: require('/workspace/components/account/Appbar.vue').default,UiWhatsappIcon: require('/workspace/components/ui/WhatsappIcon.vue').default})
