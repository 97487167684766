
import { mapActions, mapGetters } from 'vuex'
import { locationDecoder } from '@/utils/google-maps'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    address: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      branchId: null,
      place: null,
      locationAddress: null,
    }
  },
  computed: {
    ...mapGetters({
      currentCommerce: 'currentCommerce',
      currentBranch: 'currentBranch',
      branches: 'branch/branches',
    }),
  },
  watch: {
    visible(value) {
      if (!value) return

      if (this.currentBranch) {
        this.branchId = this.currentBranch.id;
      }
    },
  },
  methods: {
    ...mapActions({
      changeBranch: 'changeBranch',
    }),
    handleConfirm() {
      this.changeBranch({ branchId: this.branchId });
      this.$emit('update:visible', false);
      this.$notification.info(this.$t('message.branch_change_success'));
      this.$auth.$storage.setUniversal('location', this.currentBranch?.city);
      this.$reload();
    }
    ,
    handleCancel() {
      this.$emit('update:visible', false)
    },
    handlePlace(place) {
      const location = locationDecoder(place);
      this.place = location
      if (location) {
        this.$auth.$storage.setUniversal('location', location?.city);
        this.$auth.$storage.setUniversal('shippingAddress', JSON.stringify(location));
      }
    }
  },
}
