export const state = () => ({
  bankAccounts: [],
  pagination: {
    current_page: 1,
    per_page: 12,
    last_page: 0,
    total: 0,
  },
})

export const getters = {
  bankAccounts: (state) => state.bankAccounts,
  defaultBankAccount: (state) =>
    state.bankAccounts.find((account) => account.default),
  bankAccountById:
    (state) =>
    ({ bankAccountId }) =>
      state.bankAccounts.find(
        (bankAccount) => bankAccount.id === bankAccountId
      ),
  pagination: (state) => state.pagination,
}

export const mutations = {
  SET_BANK_ACCOUNTS: (state, { bankAccounts }) =>
    (state.bankAccounts = bankAccounts),
  ADD_BANK_ACCOUNT: (state, { bankAccount }) =>
    state.bankAccounts.unshift(bankAccount),
  UPDATE_BANK_ACCOUNT: (state, { bankAccount }) => {
    const bankAccountFound = state.bankAccounts.findIndex(
      ({ id }) => id === bankAccount.id
    )

    if (bankAccountFound === -1) return

    state.bankAccounts.splice(bankAccountFound, 1, bankAccount)
  },
  REMOVE_BANK_ACCOUNT: (state, { bankAccountId }) => {
    const bankAccountFound = state.bankAccounts.findIndex(
      ({ id }) => id === bankAccountId
    )

    if (bankAccountFound === -1) return

    state.bankAccounts.splice(bankAccountFound, 1)
  },
  UPDATE_DEFAULT_BANK_ACCOUNT: (state, { bankAccount }) => {
    if (!bankAccount.default) return

    state.bankAccounts = state.bankAccounts.map((account) => ({
      ...account,
      default: account.id === bankAccount.id,
    }))
  },
  SET_PAGINATION: (state, { meta }) => (state.pagination = meta),
}

export const actions = {
  async fetchBankAccounts({ commit }, { branchId, params = {} }) {
    return await this.$axios
      .$get(`/api/v1/private/branches/${branchId}/bank-accounts`, { params })
      .then(({ bank_accounts: bankAccounts, meta }) => {
        commit('SET_BANK_ACCOUNTS', { bankAccounts })
        commit('SET_PAGINATION', { meta })

        return { bankAccounts, meta }
      })
  },
  async fetchBankAccount({ commit, getters }, { bankAccountId }) {
    return await this.$axios
      .$get(`/api/v1/private/bank-accounts/${bankAccountId}`)
      .then(({ bank_account: bankAccount }) => {
        getters.bankAccountById({ bankAccountId })
          ? commit('UPDATE_BANK_ACCOUNT', { bankAccount })
          : commit('ADD_BANK_ACCOUNT', { bankAccount })

        commit('UPDATE_DEFAULT_BANK_ACCOUNT', { bankAccount })

        return { bankAccount }
      })
  },
  async createBankAccount({ commit, getters }, { branchId, data }) {
    return await this.$axios
      .$post(`/api/v1/private/branches/${branchId}/bank-accounts`, data)
      .then(({ bank_account: bankAccount }) => {
        commit('ADD_BANK_ACCOUNT', { bankAccount })
        commit('UPDATE_DEFAULT_BANK_ACCOUNT', { bankAccount })

        return { bankAccount }
      })
  },
  async updateBankAccount({ commit, getters }, { bankAccountId, data }) {
    return await this.$axios
      .$put(`/api/v1/private/bank-accounts/${bankAccountId}`, data)
      .then(({ bank_account: bankAccount }) => {
        commit('UPDATE_BANK_ACCOUNT', { bankAccount })
        commit('UPDATE_DEFAULT_BANK_ACCOUNT', { bankAccount })

        return { bankAccount }
      })
  },
  async deleteBankAccount({ commit, getters }, { bankAccountId }) {
    return await this.$axios
      .$delete(`/api/v1/private/bank-accounts/${bankAccountId}`)
      .then(() => {
        commit('REMOVE_BANK_ACCOUNT', { bankAccountId })
      })
  },
}
