export const state = () => ({
  productCategories: [],
  pagination: {},
})

export const getters = {
  productCategories: (state) => state.productCategories,
  productCategoryById:
    (state) =>
      ({ productCategoryId }) =>
        state.productCategories.find(
          (productCategory) => productCategory.id === productCategoryId
        ),
  pagination: (state) => state.pagination,
}

export const mutations = {
  SET_PRODUCT_CATEGORIES: (state, { productCategories }) =>
    (state.productCategories = productCategories),
  ADD_PRODUCT_CATEGORY: (state, { productCategory }) =>
    state.productCategories.unshift(productCategory),
  UPDATE_PRODUCT_CATEGORY: (state, { productCategory }) => {
    const productCategoryFound = state.productCategories.findIndex(
      ({ id }) => id === productCategory.id
    )

    if (productCategoryFound === -1) return

    state.productCategories.splice(productCategoryFound, 1, productCategory)
  },
  REMOVE_PRODUCT_CATEGORY: (state, { productCategoryId }) => {
    const productCategoryFound = state.productCategories.findIndex(
      ({ id }) => id === productCategoryId
    )

    if (productCategoryFound === -1) return

    state.productCategories.splice(productCategoryFound, 1)
  },
  SET_PAGINATION: (state, { meta }) => (state.pagination = meta),
}

export const actions = {
  async fetchProductCategories({ commit }, { params = {} } = {}) {
    return await this.$axios
      .$get(`/api/v1/public/product-categories`, {
        params,
      })
      .then(({ product_categories: productCategories, meta }) => {
        commit('SET_PRODUCT_CATEGORIES', { productCategories })
        commit('SET_PAGINATION', { meta })

        return { productCategories, meta }
      })
  },
  async fetchProductCategory({ commit, getters }, { productCategoryId }) {
    return await this.$axios
      .$get(`/api/v1/public/product-categories/${productCategoryId}`)
      .then(({ product_category: productCategory }) => {
        getters.productCategoryById({ productCategoryId })
          ? commit('UPDATE_PRODUCT_CATEGORY', { productCategory })
          : commit('ADD_PRODUCT_CATEGORY', { productCategory })

        return { productCategory }
      })
  },
  addProductCategory({ commit, getters }, { productCategory }) {
    getters.productCategoryById({ productCategoryId: productCategory.id })
      ? commit('UPDATE_PRODUCT_CATEGORY', { productCategory })
      : commit('ADD_PRODUCT_CATEGORY', { productCategory })
  }
}
