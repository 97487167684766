import dayjs from 'dayjs'

import 'dayjs/locale/es'
import weekday from 'dayjs/plugin/weekday'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(weekday)
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)
dayjs.extend(customParseFormat)

dayjs.locale('es')

export default (context, inject) => {
  context.$dayjs = dayjs
  inject('dayjs', dayjs)
}
