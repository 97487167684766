export const state = () => ({
  neighborhoodGroups: [],
  pagination: {
    current_page: 1,
    per_page: 12,
    last_page: 0,
    total: 0,
  },
})

export const getters = {
  neighborhoodGroups: (state) => state.neighborhoodGroups,
  neighborhoodGroupsById:
    (state) =>
    ({ neighborhoodGroupId }) =>
      state.neighborhoodGroups.find(
        (neighborhoodGroup) => neighborhoodGroup.id === neighborhoodGroupId
      ),
  pagination: (state) => state.pagination,
}

export const mutations = {
  SET_NEIGHBORHOOD_GROUPS: (state, { neighborhoodGroups }) =>
    (state.neighborhoodGroups = neighborhoodGroups),
  ADD_NEIGHBORHOOD_GROUP: (state, { neighborhoodGroup }) =>
    state.neighborhoodGroups.unshift(neighborhoodGroup),
  UPDATE_NEIGHBORHOOD_GROUP: (state, { neighborhoodGroup }) => {
    const neighborhoodGroupFound = state.neighborhoodGroups.findIndex(
      ({ id }) => id === neighborhoodGroup.id
    )

    if (neighborhoodGroupFound === -1) return

    state.neighborhoodGroups.splice(
      neighborhoodGroupFound,
      1,
      neighborhoodGroup
    )
  },
  REMOVE_NEIGHBORHOOD_GROUP: (state, { neighborhoodGroupId }) => {
    const neighborhoodGroupFound = state.neighborhoodGroups.findIndex(
      ({ id }) => id === neighborhoodGroupId
    )

    if (neighborhoodGroupFound === -1) return

    state.neighborhoodGroups.splice(neighborhoodGroupFound, 1)
  },
  SET_PAGINATION: (state, { meta }) => (state.pagination = meta),
}

export const actions = {
  async fetchNeighborhoodGroups({ commit }, { branchId, params = {} }) {
    return await this.$axios
      .$get('/api/v2/neighborhood-groups', {
        params: { ...params, branch: branchId },
      })
      .then(({ neighborhood_groups: neighborhoodGroups, meta }) => {
        commit('SET_NEIGHBORHOOD_GROUPS', { neighborhoodGroups })
        commit('SET_PAGINATION', { meta })

        return { neighborhoodGroups, meta }
      })
  },
  async fetchNeighborhoodGroup({ commit, getters }, { neighborhoodGroupId }) {
    return await this.$axios
      .$get(`/api/v2/neighborhood-groups/${neighborhoodGroupId}`)
      .then(({ neighborhood_group: neighborhoodGroup }) => {
        getters.neighborhoodGroupsById({ neighborhoodGroup })
          ? commit('UPDATE_NEIGHBORHOOD_GROUP', { neighborhoodGroup })
          : commit('ADD_NEIGHBORHOOD_GROUP', { neighborhoodGroup })

        return { neighborhoodGroup }
      })
  },
  async createNeighborhoodGroup({ commit, getters }, { branchId, data }) {
    return await this.$axios
      .$post('/api/v2/neighborhood-groups', { ...data, branch_id: branchId })
      .then(({ neighborhood_group: neighborhoodGroup }) => {
        commit('ADD_NEIGHBORHOOD_GROUP', { neighborhoodGroup })

        return { neighborhoodGroup }
      })
  },
  async updateNeighborhoodGroup(
    { commit, getters },
    { neighborhoodGroupId, data }
  ) {
    return await this.$axios
      .$put(`/api/v2/neighborhood-groups/${neighborhoodGroupId}`, data)
      .then(({ neighborhood_group: neighborhoodGroup }) => {
        commit('UPDATE_NEIGHBORHOOD_GROUP', { neighborhoodGroup })

        return { neighborhoodGroup }
      })
  },
  async deleteNeighborhoodGroup({ commit, getters }, { neighborhoodGroupId }) {
    return await this.$axios
      .$delete(`/api/v2/neighborhood-groups/${neighborhoodGroupId}`)
      .then(() => {
        commit('REMOVE_NEIGHBORHOOD_GROUP', { neighborhoodGroupId })
      })
  },
}
