
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: mapGetters(['currentCommerce']),
  methods: {
    ...mapActions(['changeCommerce', 'changeBranch']),
    handleChangeCommerce({ commerceId }) {
      this.changeCommerce({ commerceId })

      this.$router.push(this.localeRoute('dashboard'))
    },
    handleClose() {
      this.$emit('close')
    },
  },
}
