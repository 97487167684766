
export default {
  props: {
    collapsed: {
      type: Boolean,
      default: false,
    },
    hideLogo: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleToggleVisible() {
      this.$emit('toggle-visible')
    },
    handleToggleCollapse() {
      this.$emit('update:collapsed', !this.collapsed)
    },
  },
}
