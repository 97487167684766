export default async ({ store, $auth, redirect, localeRoute }) => {
  try {
    const id = $auth.$storage.getUniversal('commerce_id');
    if ($auth.loggedIn && id) {
      const { commerce } = await store.dispatch('commerce/fetchCommerce', {
        commerceId: id,
      });
      if (commerce.commerce_type === 'wholesaler') {
        redirect(localeRoute('account'));
      }

    }
  } catch (error) {
    console.error(error);
  }
};
