export default {
  data() {
    return {
      viewportHeight: 100,
    }
  },
  mounted() {
    this.determineViewportHeight()

    window.addEventListener('resize', this.determineViewportHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.determineViewportHeight)
  },
  methods: {
    determineViewportHeight() {
      this.viewportHeight = window.innerHeight

      document.body.style.height = `${this.viewportHeight}px`
    },
    // adjustDrawerHeight() {
    //   const windowHeight = window.innerHeight
    //
    //   const navigationDrawerElement = this.$refs.navigationDrawer.$el
    //
    //   const topBarHeight = windowHeight - navigationDrawerElement.clientHeight
    //
    //   navigationDrawerElement.style.height = `calc(${windowHeight}px - ${topBarHeight}px)`
    // },
  },
}

// :class="{ 'small-height': isSmallDevice }"

/// <style>
// .small-height {
//   height: 88vh !important;
// }
// </style>
