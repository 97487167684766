export default async ({ store, $auth }) => {
  const { loggedIn } = $auth;

  if (loggedIn) {
    try {
      const { currentBranchId } = store.state;
      await store.dispatch('permission/fetchPermissions', { branchId: currentBranchId });
    } catch (error) {
      console.error(error);
    }
  }
}
