import { extend, setInteractionMode } from 'vee-validate'
import {
  required,
  required_if as requiredIf,
  email,
  confirmed,
  oneOf,
  regex,
  length,
  numeric,
  min,
  min_value as minValue,
  max_value as maxValue,
  max,
  digits,
  image,
  ext,
  dimensions,
} from 'vee-validate/dist/rules'
import { identification } from './rules'

extend('confirmed', confirmed)
extend('email', email)
extend('required', required)
extend('oneOf', oneOf)
extend('regex', regex)
extend('length', length)
extend('numeric', numeric)
extend('required_if', requiredIf)
extend('min', min)
extend('max', max)
extend('min_value', minValue)
extend('max_value', maxValue)
extend('identification', identification)
extend('digits', digits)
extend('image', image)
extend('ext', ext)
extend('dimensions', dimensions)

setInteractionMode('lazy')

extend('accepted', (value) => {
  if (value) return true

  return 'Los {_field_} debe ser aceptado.'
})
