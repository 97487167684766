import { findIndex } from 'lodash'

export const state = () => ({
  orders: [],
  pagination: {},
})

export const getters = {
  orders: (state) => state.orders,
  orderById:
    (state) =>
    ({ orderId }) =>
      state.orders.find((order) => order.id === Number(orderId)),
  orderByStatus:
    (state) =>
    ({ orderStatus }) =>
      state.orders.filter((order) => order.status === orderStatus),
  pagination: (state) => state.pagination,
}

export const mutations = {
  SET_ORDERS: (state, { orders }) => (state.orders = orders),
  ADD_ORDER: (state, { order }) => state.orders.unshift(order),
  UPDATE_ORDER: (state, { order }) => {
    const index = findIndex(state.orders, (item) => item.id === order.id)

    if (index === -1) return

    state.orders.splice(index, 1, order)
  },
  REMOVE_ORDER: (state, { orderId }) => {
    const index = findIndex(state.orders, (item) => item.id === orderId)

    if (index === -1) return

    state.orders.splice(index, 1)
  },
  SET_PAGINATION: (state, { pagination }) => (state.pagination = pagination),
}

export const actions = {
  async fetchOrders({ commit }, { branchId, params = {} }) {
    await this.$axios
      .$get(`/api/v1/private/branches/${branchId}/orders`, { params })
      .then(({ orders, meta }) => {
        commit('SET_ORDERS', { orders })
        commit('SET_PAGINATION', { pagination: meta })
      })
  },
  async loadOrders({ commit, getters }, { branchId, params = {} }) {
    await this.$axios
      .$get(`/api/v1/private/branches/${branchId}/orders`, { params })
      .then(({ orders }) => {
        orders.forEach((order) => {
          getters.orderById({ orderId: order.id })
            ? commit('UPDATE_ORDER', { order })
            : commit('ADD_ORDER', { order })
        })
      })
  },
  async fetchOrder({ commit, getters }, { orderId }) {
    return await this.$axios
      .$get(`/api/v1/private/orders/${orderId}`)
      .then(({ order }) => {
        getters.orderById({ orderId })
          ? commit('UPDATE_ORDER', { order })
          : commit('ADD_ORDER', { order })

        return { order }
      })
  },
  async createOrder({ commit }, { data }) {
    return await this.$axios.$post('/api/v2/orders', data).then(({ order }) => {
      commit('ADD_ORDER', { order })

      return order
    })
  },
  async updateOrder({ commit }, { orderId, data }) {
    return await this.$axios
      .$put(`/api/v1/private/orders/${orderId}`, data)
      .then(({ order }) => commit('UPDATE_ORDER', { order }))
  },
  async deleteOrder({ commit }, { orderId }) {
    return await this.$axios
      .$delete(`/api/v2/orders/${orderId}`)
      .then(() => commit('REMOVE_ORDER', { orderId }))
  },
}
