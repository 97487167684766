export const state = () => ({
  cart_orders: [],
  shipping: {},
})

export const getters = {
  orders: (state) =>
    state.cart_orders.map((order) => ({
      ...order,
      total: order.cart_items.reduce(
        (total, item) =>
          total +
          (item.offer_enabled ? item.offer_price : item.price) * item.quantity,
        0
      ),
    })),
  orderByBranchId:
    (state, getters) =>
      ({ branchId }) =>
        getters.orders.find((order) => order.branch.id === branchId),
  orderByVariantId:
    (state, getters) =>
      ({ variantId }) =>
        getters.orders.find((order) =>
          order.cart_items.some((item) => item.product_variant_id === variantId)
        ),
  itemByVariantId:
    (state) =>
      ({ variantId }) =>
        state.cart_orders
          .map((order) => order.cart_items)
          .flat()
          .find((item) => item.product_variant_id === variantId),
  itemCount: (state) =>
    state.cart_orders
      .map((order) => order.cart_items.map((item) => item.quantity))
      .flat()
      .reduce((total, quantity) => total + quantity, 0),
}

export const mutations = {
  SET_ORDERS: (state, payload) => (state.cart_orders = payload),
  ADD_ORDER: (state, payload) => state.cart_orders.push(payload),
  UPDATE_ORDER: (state, payload) => {
    const orderIdx = state.cart_orders.findIndex(
      (order) => order.branch.id === payload.branch_id
    )

    state.cart_orders.splice(orderIdx, 1, payload)
  },
  REMOVE_ORDER: (state, payload) => {
    const orderIdx = state.cart_orders.findIndex(
      (order) => order.branch.id === payload.branch_id
    )

    state.cart_orders[orderIdx]._destroy = true
  },
  ADD_ITEM: (state, payload) => {
    const order = state.cart_orders.find(
      (order) => order.branch.id === payload.branch_id
    )

    order.cart_items.push(payload)
  },
  UPDATE_ITEM: (state, payload) => {
    const orderIdx = state.cart_orders.findIndex(
      (order) => order.branch.id === payload.branch_id
    )

    const productIdx = state.cart_orders[orderIdx].cart_items.findIndex(
      (item) => item.product_variant_id === payload.product_variant_id
    )

    state.cart_orders[orderIdx].cart_items.splice(productIdx, 1, payload)
  },
  REMOVE_ITEM: (state, payload) => {
    const orderIdx = state.cart_orders.findIndex((order) =>
      order.cart_items.find(
        (item) => item.product_variant_id === payload.product_variant_id
      )
    )

    const itemIdx = state.cart_orders[orderIdx].cart_items.findIndex(
      (item) => item.product_variant_id === payload.product_variant_id
    )

    state.cart_orders[orderIdx].cart_items[itemIdx]._destroy = true
  },
}

export const actions = {
  async updateCart({ commit, state, rootState }) {
    try {
      let payload;
      const shippingAddress = this.$auth.$storage.getUniversal('shippingAddress');
      const cartData = this.$auth.$storage.getUniversal('cart');
      if (shippingAddress && cartData) {
        payload = {
          shipping: {
            method: 'delivery',
            address: shippingAddress.address,
            state: shippingAddress.state,
            city: shippingAddress.city,
            latitude: shippingAddress.latitude,
            longitude: shippingAddress.longitude
          },
          cart_orders_attributes: [{
            branch_id: cartData.branch.id,
            shipping_method: 'delivery',
            cart_items_attributes: [
              {
                product_variant_id: cartData.variant.id,
                quantity: cartData.quantity,
                _destroy: false
              }
            ],
            _destroy: false
          }]
        };
      } else {
        payload = {
          cart_orders_attributes: state.cart_orders.map((order) => ({
            id: order.id,
            shipping_method: order.shipping_method || 'delivery',
            cart_items_attributes: order.cart_items.map((item) => ({
              id: item.id,
              product_variant_id: item.product_variant_id,
              quantity: item.quantity,
              _destroy: item._destroy,
            })),
            branch_id: order.branch.id,
            _destroy: order._destroy,
          })),
          branch_id: rootState.currentBranchId,
          shipping: state.shipping,
        };
      }

      const { cart } = await this.$axios.$put('/api/v1/private/carts', payload);
      commit('SET_CURRENT_CART_ID', { cartId: cart.id }, { root: true });
      commit('SET_ORDERS', cart.cart_orders);
    } catch (e) {
      // Handle error
      console.error('Error updating cart:', e);
    }
  },
  async addItem(
    { commit, dispatch, getters },
    { branch, variant, quantity = 1 }
  ) {
    if (!getters.orderByBranchId({ branchId: branch.id })) {
      commit('ADD_ORDER', {
        branch,
        shipping_method: 'delivery',
        cart_items: [],
        _destroy: false,
      })
    }

    const element = getters.itemByVariantId({ variantId: variant.id })

    if (!element) {
      commit('ADD_ITEM', {
        product_variant_id: variant.id,
        branch_id: branch.id,
        _destroy: false,
        quantity,
      })
    } else {
      commit('UPDATE_ITEM', {
        ...element,
        branch_id: branch.id,
        quantity: element.quantity + quantity,
      })
    }

    await dispatch('updateCart')
  },
  async removeItem(
    { commit, dispatch, getters },
    { branchId, variantId, quantity = 1 }
  ) {
    const order = getters.orderByBranchId({ branchId })
    const element = getters.itemByVariantId({ variantId })

    if (!order || !element) return

    const newQuantity = element.quantity - quantity

    if (newQuantity === 0) {
      commit('REMOVE_ITEM', {
        product_variant_id: variantId,
      })

      if (order.cart_items.filter((item) => !item._destroy).length === 0) {
        commit('REMOVE_ORDER', { branch_id: branchId })
      }
    } else {
      commit('UPDATE_ITEM', {
        ...element,
        branch_id: branchId,
        quantity: newQuantity,
      })
    }

    await dispatch('updateCart')
  },
  async changeShippingMethod(
    { commit, dispatch, getters },
    { branchId, shippingMethod }
  ) {
    const order = getters.orderByBranchId({ branchId })

    if (order) {
      commit('UPDATE_ORDER', {
        ...order,
        branch_id: branchId,
        shipping_method: shippingMethod,
      })

      await dispatch('updateCart')
    }
  },
}
