export default {
  validate: (value) => {
    const identification = value.replace('‐', '-').replace('K', 'k')

    if (!/^[0-9]+[-|‐]{1}[0-9k]{1}$/.test(identification)) return false

    // const segments = identification.split('-')
    // const verifierSegment = segments[1]
    // const identificationSegment = segments[0]

    // return getVerifierSegment(identificationSegment) === verifierSegment
    return true
  },
  message: 'El campo {_field_} no es valido.',
}

// function getVerifierSegment(T) {
//   let M = 0
//   let S = 1
//
//   for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11
//
//   return S ? `${S - 1}` : 'k'
// }
