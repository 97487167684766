
export default {
  name: 'LandingLayout',
  data() {
    return {
      isVisible: false,
      retailerMenuItems: [
        {
          label: this.$t('menu.how_does_it_work'),
          goto: "#starting"
        },
        {
          label: this.$t('menu.features'),
          goto: "#features"
        },
        {
          label: this.$t('menu.testimonials'),
          goto: "#testimonials"
        },
        {
          label: this.$t('menu.how_much'),
          goto: "#how-much"
        },
        {
          label: this.$t('menu.contact'),
          goto: "#faq"
        },
        {
          label: this.$t('menu.blog'),
          goto: "#blog"
        },
      ],
      whosalerMenuItems: [
        {
          label: this.$t('menu.how_does_it_work'),
          goto: "#starting"
        },
        {
          label: this.$t('menu.features'),
          goto: "#features"
        },
        {
          label: this.$t('menu.testimonials'),
          goto: "#testimonials"
        },
        {
          label: this.$t('menu.how_much'),
          goto: "#how-much"
        },
        {
          label: this.$t('menu.contact'),
          goto: "#faq"
        },
        {
          label: this.$t('menu.help_center'),
          goto: "#contact-us"
        },
      ],
      indexMenuItems: [
        {
          label: this.$t('menu.our_network'),
          goto: null
        },
        {
          label: this.$t('menu.what_is_alseco'),
          goto: null,
          externalGoto: 'https://queesalseco.cl/',
        },
        {
          label: this.$t('menu.publish_as_supplier'),
          actionLink: 'suppliers'
        }
      ]
    }
  },
  head() {
    return {
      meta: [
        { hid: 'description', name: 'description', content: this.$t('message.home_page') },
        { hid: 'keywords', name: 'keywords', content: this.$t('message.keywords') },
        { hid: 'og:title', property: 'og:title', content: this.$t('message.home_page') },
        { hid: 'og:description', property: 'og:description', content: this.$t('message.home_page') }
      ]
    }
  },
  computed: {
    menuItems() {
      let items = []

      if (this.getRouteBaseName() === 'commerces') items = this.retailerMenuItems
      if (this.getRouteBaseName() === 'suppliers') items = this.whosalerMenuItems
      if (this.getRouteBaseName() === 'index') items = this.indexMenuItems

      return items
    }
  },
}
