export const state = () => ({
  productTemplates: [],
  pagination: {
    current_page: 1,
    per_page: 12,
    last_page: 0,
    total: 0,
  },
})

export const getters = {
  productTemplates: (state) => state.productTemplates,
  productTemplateById:
    (state) =>
    ({ productTemplateId }) =>
      state.productTemplates.find(
        (productTemplate) => productTemplate.id === productTemplateId
      ),
  pagination: (state) => state.pagination,
}

export const mutations = {
  SET_PRODUCT_TEMPLATES: (state, { productTemplates }) =>
    (state.productTemplates = productTemplates),
  ADD_PRODUCT_TEMPLATE: (state, { productTemplate }) =>
    state.productTemplates.unshift(productTemplate),
  UPDATE_PRODUCT_TEMPLATE: (state, { productTemplate }) => {
    const productTemplateFound = state.productTemplates.findIndex(
      ({ id }) => id === productTemplate.id
    )

    if (productTemplateFound === -1) return

    state.productTemplates.splice(productTemplateFound, 1, productTemplate)
  },
  REMOVE_PRODUCT_TEMPLATE: (state, { productTemplateId }) => {
    const productTemplateFound = state.productTemplates.findIndex(
      ({ id }) => id === productTemplateId
    )

    if (productTemplateFound === -1) return

    state.productTemplates.splice(productTemplateFound, 1)
  },
  SET_PAGINATION: (state, { meta }) => (state.pagination = meta),
}

export const actions = {
  async fetchProductTemplates({ commit }, { params = {} } = {}) {
    return await this.$axios
      .$get('/api/v1/private/product-templates', { params })
      .then(({ product_templates: productTemplates, meta }) => {
        commit('SET_PRODUCT_TEMPLATES', { productTemplates })
        commit('SET_PAGINATION', { meta })

        return { productTemplates, meta }
      })
  },
  async fetchProductTemplate({ commit, getters }, { productTemplateId }) {
    return await this.$axios
      .$get(`/api/v1/private/product-templates/${productTemplateId}`)
      .then(({ product_template: productTemplate }) => {
        getters.productTemplateById({ productTemplateId })
          ? commit('UPDATE_PRODUCT_TEMPLATE', { productTemplate })
          : commit('ADD_PRODUCT_TEMPLATE', { productTemplate })

        return { productTemplate }
      })
  },
}
