
export default {
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  methods: {
    handleClose() {
      this.$emit('update:visible', false)
    },
  },
}
