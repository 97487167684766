export const state = () => ({
  collaborators: [],
  pagination: {
    current_page: 1,
    per_page: 12,
    last_page: 0,
    total: 0,
  },
})

export const getters = {
  collaborators: (state) => state.collaborators,
  collaboratorById:
    (state) =>
    ({ collaboratorId }) =>
      state.collaborators.find(
        (collaborator) => collaborator.id === collaboratorId
      ),
  pagination: (state) => state.pagination,
}

export const mutations = {
  SET_COLLABORATORS: (state, { collaborators }) =>
    (state.collaborators = collaborators),
  ADD_COLLABORATOR: (state, { collaborator }) =>
    state.collaborators.unshift(collaborator),
  UPDATE_COLLABORATOR: (state, { collaborator }) => {
    const collaboratorFound = state.collaborators.findIndex(
      ({ id }) => id === collaborator.id
    )

    if (collaboratorFound === -1) return

    state.collaborators.splice(collaboratorFound, 1, collaborator)
  },
  REMOVE_COLLABORATOR: (state, { collaboratorId }) => {
    const collaboratorFound = state.collaborators.findIndex(
      ({ id }) => id === collaboratorId
    )

    if (collaboratorFound === -1) return

    state.collaborators.splice(collaboratorFound, 1)
  },
  SET_PAGINATION: (state, { meta }) => (state.pagination = meta),
}

export const actions = {
  async fetchCollaborators({ commit }, { commerceId, params = {} }) {
    return await this.$axios
      .$get(`/api/v1/private/commerces/${commerceId}/collaborators`, { params })
      .then(({ collaborators, meta }) => {
        commit('SET_COLLABORATORS', { collaborators })
        commit('SET_PAGINATION', { meta })

        return { collaborators, meta }
      })
  },
  async fetchCollaborator({ commit, getters }, { collaboratorId }) {
    return await this.$axios
      .$get(`/api/v1/private/collaborators/${collaboratorId}`)
      .then(({ collaborator }) => {
        getters.collaboratorById({ collaboratorId })
          ? commit('UPDATE_COLLABORATOR', { collaborator })
          : commit('ADD_COLLABORATOR', { collaborator })

        return { collaborator }
      })
  },
  async createCollaborator({ commit, getters }, { commerceId, data }) {
    return await this.$axios
      .$post(`/api/v1/private/commerces/${commerceId}/collaborators`, data)
      .then(({ collaborator }) => {
        commit('ADD_COLLABORATOR', { collaborator })

        return { collaborator }
      })
  },
  async updateCollaborator({ commit, getters }, { collaboratorId, data }) {
    return await this.$axios
      .$put(`/api/v1/private/collaborators/${collaboratorId}`, data)
      .then(({ collaborator }) => {
        commit('UPDATE_COLLABORATOR', { collaborator })

        return { collaborator }
      })
  },
  async deleteCollaborator({ commit, getters }, { collaboratorId }) {
    return await this.$axios
      .$delete(`/api/v1/private/collaborators/${collaboratorId}`)
      .then(() => {
        commit('REMOVE_COLLABORATOR', { collaboratorId })
      })
  },
}
