import numeral from 'numeral'

require('numeral/locales/es')

export default ({ $config }, inject) => {
  if ($config.appLocale !== 'en') {
    const lang = require
      .context('numeral/locales', false, /\.js$/i)
      .keys()
      .map((item) => item.replace(/(^.\/)|(\.js$)/g, ''))
      .find((item) => item === $config.appLocale)

    if (lang) {
      require(`numeral/locales/${lang}`)
      numeral.locale(lang)
    }
  }

  inject('numeral', numeral)
}
