
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      showName: null,
    }
  },
  computed: {
    ...mapGetters({
      currentBranch: 'currentBranch',
      productCategories: 'productCategory/productCategories',
    }),
  },
  mounted() {
    this.showName = this.currentBranch ? this.currentBranch.name : this.$auth.$storage.getUniversal('location')
  },
}
